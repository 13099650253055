<template>
  <v-dialog
    v-model="dialog"
    persistent
    width="660"
    class="wrapper-1 bg-white"
    :content-class="isDarkMode ? 'dark-mode' : ''"
  >
    <v-card class="wrapper-1 fs-1-25r">
      <v-card-title class="bg-grey fs-1-5r">
        <span class="ma-auto">More About File</span>
      </v-card-title>

      <v-card-text
        v-if="fileData"
        style="max-height: 65vh"
        class="pa-2 overflow-auto fs-1-12r"
      >
        <!--DATA ABOUT FILE-->

        <div
          v-for="item of fileDataBuilder"
          :key="item.label"
          class="my-1 mx-2 d-flex"
        >
          <b class="min-w-125">{{ item.label }}:</b>
          {{
            item.type
              ? getDataByType(item.type, fileData[item.model])
              : fileData[item.model]
          }}
        </div>

        <v-divider class="my-2"></v-divider>
        <div class="d-flex">
          <span class="w-210"></span>
          <span class="fs-1-25r font-weight-bold w-210 text-center">Local</span>
          <span class="fs-1-25r font-weight-bold w-210 text-center">UTC</span>
        </div>
        <!--DATA ABOUT FILE LIFETIME-->
        <div
          v-for="item of lifeTimeBuilder"
          :key="item.label"
          class="my-1 mx-2 d-flex"
        >
          <!--TITLE-->
          <b class="w-210">{{ item.label }}:</b>
          <!--LOCAL-->
          <span class="w-210 text-center">
            {{ generateLocalDateFromUTC0David(fileData[item.model], true) }}
          </span>
          <!--UTC+0-->
          <span class="w-210 text-center">
            {{ generateUTCDateDavid(fileData[item.model], true) }}
          </span>
        </div>

        <v-divider class="my-2"></v-divider>

        <!--RECIPIENTS-->
        <div>
          <span class="fs-1-25r mb-2 font-weight-bold">Recipients:</span>
          <ul>
            <li v-for="user of fileData.recipientDetails" :key="user.email">
              {{ user.email }}
            </li>
          </ul>
        </div>
      </v-card-text>

      <div v-else class="text-center ma-3 fs-1-12r">File was not found.</div>

      <!--CLOSE-->
      <div class="d-flex justify-end pa-2">
        <Button text="Close" :clickAction="closeFilesDialog" btnType="grey" />
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { api } from '@/config'
import {
  bytesToSize,
  generateUTCDateDavid,
  generateLocalDateFromUTC0David,
} from '@/utils'
import Button from '@/components/BaseComponents/Button/Button.vue'

export default {
  name: 'FileDataDialog',
  data() {
    return {
      dialog: true,
      fileData: null,
      generateUTCDateDavid,
      generateLocalDateFromUTC0David,
      fileDataBuilder: [
        // fileName
        {
          label: 'Name',
          model: 'fileName',
        },
        // originalSize
        {
          label: 'Original size',
          model: 'originalSize',
          type: 'size',
        },
        // currentSize // after filtering size
        {
          label: 'Filtered size',
          model: 'currentSize',
          type: 'size',
        },
        // operationId
        {
          label: 'Operation ID',
          model: 'operationId',
        },
        // IF ROUTE routeId
        {
          label: 'Route ID',
          model: 'routeId',
        },
        // relativePath
        {
          label: 'Relative path',
          model: 'relativePath',
        },
        // IF SUPER ADMIN - organizationId
        // {
        //   label: 'Host Name',
        //   model: 'organizationId',
        //   type: 'orgId',
        // },
        // senderEmail //SENDER
        {
          label: 'Sender',
          model: 'senderEmail',
        },
        // historyId
        {
          label: 'History ID',
          model: 'historyId',
        },
        // policy
        {
          label: 'Policy',
          model: 'policy',
        },
        // subject
        {
          label: 'Subject',
          model: 'subject',
        },
        // message
        {
          label: 'Message',
          model: 'message',
        },
      ],
      lifeTimeBuilder: [
        { label: 'Uploading started', model: 'dateUploadingStarted' },
        { label: 'Uploading finished', model: 'dateSentToEngine' },
        { label: 'Sent to SelectorIT', model: 'dateSentToEngine' },
        { label: 'Pulled from SelectorIT', model: 'datePulledFromEngine' },
        { label: 'Written to disk', model: 'dateWrittenToDisk' },
        { label: 'Alert before deletion', model: 'dateAlertBeforeDeletion' }, //change this - sent only in 12:00 and 00:00
        {
          label: 'Alert Before deletion sent',
          model: 'dateActualAlertBeforeDeletion',
        },
        { label: 'Scheduled deletion', model: 'dateScheduledDeletion' }, // change this - round up from 2:35 to 3:00
        { label: 'Deleted', model: 'dateActualDeletion' },
        { label: 'Deleted by system', model: 'deletedBySystem' },
      ],
    }
  },

  props: {
    closeFilesDialog: Function,
    fileGuid: String,
  },
  computed: {
    ...mapGetters(['isDarkMode']),
  },
  methods: {
    ...mapMutations(['SET_PROCESSING']),
    getDataByType(type, data) {
      if (type === 'size') return bytesToSize(data)
      return data
    },
  },
  async created() {
    try {
      this.SET_PROCESSING(true)
      const res = await api.get(`files/${this.fileGuid}`)
      // this.fileData = fileObj
      if (res.status !== 200) throw Error

      //this will caculate the deletion date of the file
      const deletionDate = new Date(res.data.dateScheduledDeletion)
      deletionDate.setMinutes(0)
      deletionDate.setSeconds(0)
      deletionDate.setMilliseconds(0)
      deletionDate.setHours(deletionDate.getHours() + 1)

      //this will caculate when the email will sent about deletion files
      const alertDate = new Date(res.data.dateAlertBeforeDeletion)
      const currentHour = alertDate.getHours()
      alertDate.setMinutes(0)
      alertDate.setSeconds(0)
      alertDate.setMilliseconds(0)

      if (currentHour < 12) {
        alertDate.setHours(0)
      } else {
        alertDate.setHours(12)
      }

      this.fileData = {
        ...res.data,
        dateScheduledDeletion: deletionDate.getTime(),
        dateAlertBeforeDeletion: alertDate.getTime(),
      }
    } catch (e) {
      console.log(e)
    } finally {
      this.SET_PROCESSING(false)
    }
  },
  components: { Button },
}
</script>
