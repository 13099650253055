<template>
  <v-dialog
    v-model="computedIsShowAbout"
    persistent
    transition="dialog-bottom-transition"
    max-width="800px"
    class="wrapper-1"
    flat
    :content-class="isDarkMode ? 'dark-mode' : ''"
  >
    <template v-slot:default="dialog">
      <v-card>
        <v-toolbar flat height="10px">
          <template v-slot:extension>
            <v-tabs v-model="tab" class="mb-2 mx-1">
              <v-tab href="#about">About</v-tab>
              <v-tab href="#qa">Q & A</v-tab>
              <v-tab href="#termsofuse">Terms of use</v-tab>
              <v-tab href="#contact">Contact</v-tab>
            </v-tabs>
          </template>
        </v-toolbar>
        <v-divider></v-divider>
        <v-tabs-items v-model="tab">
          <!-- ABOUT -->
          <AboutSection />

          <!-- Q & A-->
          <v-tab-item value="qa">
            <QuestionsAndAnswers />
          </v-tab-item>

          <!-- TERMS OF USE -->
          <v-tab-item value="termsofuse">
            <TermsOfUse />
          </v-tab-item>

          <!--CONTACT -->
          <Contact />
        </v-tabs-items>

        <v-card-actions class="d-flex justify-space-between pa-2 flex-wrap">
          <span v-if="$vuetify.breakpoint.width > 419" class="w-65"></span>
          <span class="light-text mx-auto">
            All rights reserved YazamTech 2024 &#169;
          </span>

          <Button
            :clickAction="() => (computedIsShowAbout = false)"
            btnType="grey"
            text="Close"
          />
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
//Base components
import Button from '@/components/BaseComponents/Button/Button.vue'
import TermsOfUse from '@/components/BaseComponents/TermsOfUse/TermsOfUse.vue'

//childrens
import QuestionsAndAnswers from './Children/QuestionsAndAnswers/QuestionsAndAnswers.vue'
import AboutSection from './Children/AboutSection/AboutSection.vue'
import Contact from './Children/Contact/Contact.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'About',
  data() {
    return {
      computedIsShowAbout: true,
      tab: null,
    }
  },
  emits: ['toggle-is-show-about'],
  components: {
    QuestionsAndAnswers,
    Button,
    TermsOfUse,
    AboutSection,
    Contact,
  },
  watch: {
    computedIsShowAbout(n) {
      this.$emit('toggle-is-show-about', n)
    },
  },
  computed: {
    ...mapGetters(['isDarkMode']),
  },
}
</script>

<style scoped src="./About.css"></style>
