<template>
  <div class="wrapper-1 pa-2 ma-2">
    <div class="d-flex align-center justify-space-between my-1">
      <div class="d-flex">
        <!--IS SUPPORTED-->
        <v-checkbox
          v-model="language.isSupported"
          :label="fullLangName"
          hide-details
          class="ma-0 pa-0 mb-2 mx-2"
          style="width: 150px"
        ></v-checkbox>

        <!--BUTTON TO SHOW LANG -->
        <Button
          :text="isShowLang ? `Hide  Translations` : `Show Translations`"
          width="230px"
          :icon="isShowLang ? 'mdi-chevron-up' : 'mdi-chevron-down'"
          btnType="grey"
          class="mx-2"
          :disabled="!language.isSupported"
          :clickAction="() => (isShowLang = !isShowLang)"
        />
      </div>

      <!--COUNTRY CODE-->
      <v-select
        v-model="language.flag"
        label="Flag"
        :items="flagList[language.lang]"
        item-text="country"
        dense
        item-value="code"
        hide-details
        class="mw-265 mx-2"
      >
        <template v-slot:item="{ item }">
          <div class="fs-1r font-weight-medium">
            <CustomFlag
              v-show="item.code !== 'no'"
              :iso="item.code"
              class="mx-1"
            />
            {{ item.country }}
          </div>
        </template>

        <template v-slot:selection="{ item }">
          <div class="fs-1r font-weight-medium">
            <CustomFlag
              v-show="item.code !== 'no'"
              :iso="item.code"
              class="mx-1"
            />
            {{ item.country }}
          </div>
        </template>
      </v-select>
    </div>
    <!--EXPANDED-->
    <div
      v-show="isShowLang"
      v-for="t of builders"
      :key="t.type"
      class="wrapper-2 ma-2 pa-2"
    >
      <!--TITLE-->
      <h4 class="my-2">{{ t.title }}</h4>

      <!--FIELDS-->
      <div class="pb-2">
        <div
          :class="[!language[t.type][item.model] && 'dead-input']"
          v-for="item of t.builder"
          :key="`${language.lang}-${item.model}`"
        >
          <v-text-field
            v-model="language[t.type][item.model]"
            :id="`id-${language.lang}-${item.model}`"
            hide-details
            dense
            :disabled="!language.isSupported"
            class="ma-0 pa-0 mx-2 my-4 mw-550"
            :label="item.label"
          >
          </v-text-field>
        </div>
      </div>
    </div>

    <!--BUTTON TO RESTORE LANG-->
    <Button
      v-if="isShowLang"
      text="Restore Default"
      icon="mdi-restore"
      btnType="grey"
      class="mx-2 mt-4"
      :disabled="!language.isSupported"
      :clickAction="restoreLang"
    />
  </div>
</template>

<script>
import Button from '@/components/BaseComponents/Button/Button.vue'
import { builders } from './LanguageSettings'
import { confirmDialog } from '@/utils'
import { api } from '@/config'
import { mapGetters, mapMutations } from 'vuex'
import { flagList } from './LanguageSettings'
import CustomFlag from './CustomFlag.vue'

export default {
  name: 'LanguageSettings',
  data() {
    return {
      isShowLang: false,
      fullLangName: '',
      builders,
      flagList,
    }
  },
  props: {
    language: Object,
    setLanguageData: Function,
  },

  computed: {
    ...mapGetters(['currentOrganization']),
  },
  methods: {
    ...mapMutations(['SET_PROCESSING']),
    async restoreLang() {
      const text = `Are you sure you want to restore the default fields of ${this.fullLangName}?`

      const thenFunc = async () => {
        try {
          this.SET_PROCESSING(true)
          const res = await api.put(
            `kiosk/${this.currentOrganization.organizationId}/restorelanguagedefaults/${this.language.lang}`
          )

          console.log(res)
          if (res.status !== 200) throw Error

          this.setLanguageData(res.data)
        } catch (e) {
          console.log(e)
        } finally {
          this.SET_PROCESSING(false)
        }
      }

      const catchFunc = () => {}

      await confirmDialog(this, text, 'Restore', 'Cancel', thenFunc, catchFunc)
    },
  },
  components: { Button, CustomFlag },
  mounted() {
    let name = ''
    switch (this.language.lang) {
      case 'he':
        name = 'Hebrew'
        break
      case 'en':
        name = 'English'
        break
      case 'ru':
        name = 'Russian'
        break
      case 'ar':
        name = 'Arabic'
        break
      case 'ro':
        name = 'Romanian'
        break
      case 'cn':
        name = 'Chinese'
        break
      case 'fr':
        name = 'French'
        break
      default:
        name = 'ERROR'
    }
    this.fullLangName = name
  },
}
</script>

<style scoped>
.dead-input {
  border: 2px dashed #e3e3e3;
  width: 600px;
}
</style>
