<template>
  <div class="casual-upload-wrapper">
    <div v-if="isAuthenticated" class="casual-upload-inner bg-white wrapper-2">
      <Header
        v-if="session?.recipientName"
        :recipientName="session.recipientName"
      />

      <div class="casual-upload-main">
        <DynamicFields
          v-if="session?.interfaceDynamicFields"
          :session="session"
          :lightSessionRestart="() => null"
          :isAuthenticateEmail="isAuthenticateEmail"
        />

        <v-checkbox
          v-if="
            session?.interfaceDynamicFields?.some(el => el.validation === 1)
          "
          v-model="isSendApprovalEmail"
          hide-details
          class="my-1"
          :label="$t('Send me a confirmation email.')"
          dense
        />

        <FileDirButtons
          class="my-2"
          :session="session"
          :lightSessionRestart="() => null"
        />

        <SelectedFilesList
          v-if="session?.filesData?.length"
          :session="session"
        />
      </div>

      <div class="button-wrapper wrapper-1">
        <Totals v-if="session?.filesData?.length" />
        <div v-else></div>

        <transition name="slide-fade">
          <span
            class="mr-8 red--text message fs-1r"
            v-if="showErrMsg && !getIsInMiddleOfFilteingData"
            >{{ errorMessage }}</span
          >
        </transition>

        <div @mouseover="showErrMsg = true" @mouseleave="showErrMsg = false">
          <Button
            :clickAction="startFilteringClicked"
            :text="$t(`Send`)"
            :disabled="!!errorMessage || getIsInMiddleOfFilteingData"
            class="custom-button"
          />
        </div>
      </div>
      <UploadProgress />
    </div>
    <div v-else>
      <TfaCode
        :setIsAuthenticated="setIsAuthenticated"
        :encryptedPhone="encryptedPhone"
        :recipientEmail="recipientEmail"
        :casualEmail="casualEmail"
        @set-token="setToken"
      />
    </div>
  </div>
</template>

<script>
import Header from '@/components/CasualInterface/CasualUpload/Header.vue'
import SubjectMessage from '@/components/CasualInterface/CasualUpload/SubjectMessage.vue'
import SelectedFilesList from '@/components/CasualInterface/CasualUpload/SelectedFilesList.vue'
import Totals from '@/components/CasualInterface/CasualUpload/Totals.vue'
import FileDirButtons from '@/components/CasualInterface/CasualUpload/FileDirButtons.vue'
import UploadProgress from '@/components/CasualInterface/CasualUpload/UploadProgress.vue'
import TfaCode from '@/components/CasualInterface/TfaCode.vue'
import DynamicFields from '@/components/CommonComponents/DynamicFields/DynamicFields.vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import Button from '@/components/BaseComponents/Button/Button.vue'
import { api } from '@/config'

export default {
  name: 'CasualUpload',
  data() {
    return {
      errorMessage: null,
      showErrMsg: false,
      session: null,
      isAuthenticated: false,
      encryptedPhone: null,
      token: null,
      recipientEmail: '',
      casualEmail: '',
      freezedDynamicFields: '',
      isAuthenticateEmail: false,
      isSendApprovalEmail: true,
    }
  },
  watch: {
    session: {
      async handler() {
        const msg = await this.checkUploadData()
        if (msg) this.errorMessage = msg
        else this.errorMessage = null
      },
      deep: true,
      immediate: true,
    },
    language: {
      async handler() {
        const msg = await this.checkUploadData()
        if (msg) this.errorMessage = msg
        else this.errorMessage = null
      },
      deep: true,
      immediate: true,
    },
    isSendApprovalEmail: {
      handler(n) {
        this.setSessionField({
          field: 'isSendApprovalEmail',
          value: n,
        })
      },
    },
    computedIsEmailVeirfied: {
      handler(n) {
        if (!n) {
          this.isSendApprovalEmail = false
        }
      },
    },
  },
  components: {
    Header,
    SubjectMessage,
    FileDirButtons,
    UploadProgress,
    SelectedFilesList,
    Button,
    Totals,
    TfaCode,
    DynamicFields,
  },

  computed: {
    ...mapGetters([
      'getSessionData',
      'getRegisteredSendTo',
      'themeSettings',
      'language',
      'getIsInMiddleOfFilteingData',
    ]),
    computedIsEmailVeirfied() {
      return this.getSessionData.isEmailVerified
    },
  },

  methods: {
    ...mapMutations([
      'setSessionField',
      'setToken',
      'SET_USER',
      'SET_SERVER_ERROR_NOTIFICATION',
    ]),
    ...mapActions([
      'checkUploadData',
      'startFiltering',
    ]),
    async startFilteringClicked() {
      let biggestFileSize = 0
      this.session.filesData.forEach(el =>
        el.file.numbSize > biggestFileSize
          ? (biggestFileSize = el.file.numbSize)
          : null
      )
      const isSessionApprovedRes = await api.get(
        `filter/sizepermissions?total=${this.session.totalSize}&biggest=${biggestFileSize}`
      )
      if (isSessionApprovedRes.status !== 200) return
      this.getSessionData.isEmailVerified = false
      this.errorMessage = ''
      this.isSendApprovalEmail = true
      this.startFiltering(this)
    },

    async setIsAuthenticated() {
      this.isAuthenticated = true
      api.defaults.headers.common['Authorization'] = `Bearer ${this.token}`
      const response = await api.get('casuals/casual-sending-details')
      if (!response.data.isRecipientLicensed) {
        this.SET_SERVER_ERROR_NOTIFICATION(
          this.$t(
            'This link is no longer valid.'
          )
        )
        this.$router.push('/')
      }
      this.freezedDynamicFields = JSON.stringify(
        response.data.interfaceDynamicFields
      )
      this.setSessionField({
        field: 'recipientEmail',
        value: response.data.registeredRecipient.email,
      })
      this.setSessionField({
        field: 'recipientName',
        value: response.data.registeredRecipient.name,
      })
      this.setSessionField({
        field: 'interfaceDynamicFields',
        value: JSON.parse(this.freezedDynamicFields),
      })
      this.session = this.getSessionData
      this.SET_USER({
        organizationId: response.data.registeredRecipient.organizationId,
      })
    },

    setToken(token) {
      this.token = token
    },
  },

  async beforeMount() {
    const { token, tfa, recipient, casualEmail } = this.$route.query
    this.recipientEmail = recipient
    this.casualEmail = casualEmail
    // check if two factor auth is needed. if so, start it's prcess.
    if (tfa === 'true' || tfa === 'True') {
      this.encryptedPhone = token
      // await api.
    }
    // if not, continue directly
    else {
      this.setToken(token)
      await this.setIsAuthenticated()
    }
  },
}
</script>

<style lang="scss" scoped>
.casual-upload-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100% - 50px);
  width: 100%;

  .casual-upload-inner {
    width: 50%;
    max-width: 550px;
    // height: 50%;
  }

  @media (max-width: 768px) {
    .casual-upload-inner {
      width: calc(100% - 20px);
    }
  }

  .casual-upload-main {
    padding: 8px;
    padding-bottom: 10px;
    max-height: 70vh;
    overflow: auto;
  }

  .button-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
    margin: 8px;

    .custom-button {
      width: 140px;
      font-size: 24px !important;
      height: 36px !important;
    }
  }
}
</style>
