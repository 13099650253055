<template>
  <v-dialog
    v-model="dialog"
    persistent
    width="800px"
    :content-class="isDarkMode ? 'dark-mode' : ''"
    style="z-index: 5001"
  >
    <div class="bg-white">
      <v-card-title class="text-h5 bg-grey font-weight-medium">
        {{ $t('Guests Phone Book') }}
      </v-card-title>

      <div class="mh-400 d-flex flex-column mt-5">
        <div class="ma-4" @click="copyLink">
          <!-- <v-text-field
            v-if="permanentLink"
            v-model="permanentLink"
            outlined
            hide-details
            :label="$t('Permanent Uploading Link')"
            type="text"
            readonly
            dense
            class="mw-400"
          >
            <template v-slot:append> -->
              
              <v-tooltip bottom >
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" class="pointer" color="blue">{{
                    linkCopyed ? 'mdi-check' : 'mdi-content-copy'
                  }}</v-icon>
                </template>

                <span>
                  {{ linkCopyed ? $t('Link copied') : $t('Copy link') }}
                </span>
              </v-tooltip>
              <a>{{ $t('Copy Permanent Uploading Link') }}</a>
            <!-- </template>
          </v-text-field> -->
        </div>
        <div>
          <div class="d-flex ml-2 justify-end">
            
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn
                  @click="addCasualUserClicked"
                  v-on="on"
                  class="mr-2 pointer small-btn-circle"
                  fab
                  color="primary"
                  outlined
                  ><v-icon>mdi-plus</v-icon></v-btn
                >
              </template>
              <span>
                {{ $t('Add guest') }}
              </span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn
                  @click="isShowPasteDialog = true"
                  v-on="on"
                  class="mr-2 pointer small-btn-circle"
                  fab
                  color="primary"
                  outlined
                  :disabled="getIsInMiddleOfFilteingData"
                >
                  <v-icon>mdi-content-paste</v-icon>
                </v-btn>
              </template>
              <span>{{ $t('Import emails') }}</span>
            </v-tooltip>
          </div>
          <v-data-table
            class="wrapper-1 ma-2"
            :headers="casualsPhoneBookHeaders"
            :items="loggedUserCasualsData"
            :options="{ sortBy: ['email'] }"
            must-sort
            :hide-default-footer="loggedUserCasualsData.length < 10"
            dense
            :noDataText="language === 'he' ? 'לא הוגדרו נמענים' : 'No recipients defined'"
          >
            <!--ADD TO SEND-->
            <!-- <template v-slot:item.addToSend="{ item }">
            <v-checkbox
            v-model="session.selectedCasualUsers"
            :value="item.email"
            hide-details
            class="pa-0 ma-0"
            >
          </v-checkbox>
        </template> -->

            <!--SEND EMAIL-->
            <template v-slot:item.actions="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon
                    @click="casualUserAddEdit = item"
                    v-on="on"
                    class="mx-1"
                    >mdi-pencil-circle-outline</v-icon
                  >
                </template>

                <span>
                  {{ $t('Edit guest') }}
                </span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon
                    v-on="on"
                    class="mx-1"
                    @click="sendMessageToCasual = item"
                    >mdi-email-outline
                  </v-icon>
                </template>

                <span>
                  {{ $t('Send a request to this guest to send files') }}
                </span>
              </v-tooltip>
            </template>
          </v-data-table>
        </div>

        
      </div>

      <v-divider></v-divider>
      <v-card-actions class="ma-0 justify-end">
        <Button :clickAction="closeDialog" :text="$t('Close')" btnType="grey" />
      </v-card-actions>
      <AddEditCasualUserPopUp
        v-if="casualUserAddEdit"
        :closeDialog="() => (casualUserAddEdit = false)"
        :casualUserAddEdit="casualUserAddEdit"
        :saveCasualUserPopUp="saveCasualUserPopUp"
        :deleteCasualClicked="deleteCasualClicked"
      />

      <SendEmailToCasualPopUp
        v-if="sendMessageToCasual"
        :closeDialog="() => (sendMessageToCasual = null)"
        :casualUser="sendMessageToCasual"
      />
      <PasteEmailsDialog
        v-if="isShowPasteDialog"
        :closeDialog="() => (isShowPasteDialog = false)"
        :session="session"
      />
    </div>
  </v-dialog>
</template>

<script>
import Button from '@/components/BaseComponents/Button/Button.vue'
import { mapGetters, mapMutations } from 'vuex'
import AddEditCasualUserPopUp from './AddEditCasualUserPopUp.vue'
import { api } from '@/config'
import { confirmDialog } from '@/utils'
import SendEmailToCasualPopUp from '@/components/CommonComponents/SendEmailToCasualPopUp.vue'
import PasteEmailsDialog from '@/components/WebsiteInterface/CommonComponents/CasualSend/Children/CasualAutocomplete/Children/PasteEmailsDialog.vue'

export default {
  name: 'CasualsPhoneBook',
  data() {
    return {
      dialog: true,
      casualUserAddEdit: null,
      sendMessageToCasual: '',
      permanentLink: null,
      linkCopyed: false,
      isShowPasteDialog: false,
    }
  },
  props: {
    closeDialog: Function,
    session: Object,
  },
  components: {
    Button,
    AddEditCasualUserPopUp,
    SendEmailToCasualPopUp,
    PasteEmailsDialog,
  },
  computed: {
    ...mapGetters([
      'isLoggedIn',
      'isInMiddleOfFiltering',
      'getIsInMiddleOfFilteingData',
      'isDarkMode',
      'loggedUser',
      'language',
    ]),
    casualsPhoneBookHeaders() {
      return [
        // {
        //   text: this.$t('Add To Send'),
        //   value: 'addToSend',
        //   width: '100px',
        //   sortable: false,
        //   class: `bg-table-heading ${
        //     this.language === 'he' ? 'table-header-last' : 'table-header-first'
        //   }`,
        // },
        {
          text: this.$t('Email'),
          value: 'email',
          width: '250px',
          class: `bg-table-heading ${
            this.language === 'he' ? 'table-header-last' : 'table-header-first'
          }`,
        },
        {
          text: this.$t('Full Name'),
          value: 'name',
          width: '150px',
          class: `bg-table-heading`,
        },
        {
          text: this.$t('Mobile'),
          value: 'phoneNumber',
          width: '150px',
          class: `bg-table-heading`,
        },

        {
          text: '',
          value: 'actions',
          align: 'center',
          width: '100px',
          sortable: false,
          class: `bg-table-heading ${
            this.language === 'he' ? 'table-header-first' : 'table-header-last'
          }`,
        },
      ]
    },
    loggedUserCasualsData() {
      return this.loggedUser.casualUsers
    },
  },
  methods: {
    ...mapMutations(['SET_PROCESSING', 'SET_NOTIFICATION']),
    addCasualUserClicked() {
      this.casualUserAddEdit = {
        email: '',
        name: '',
        phoneNumber: '',
      }
    },

    async saveCasualUserPopUp() {
      try {
        this.SET_PROCESSING(true)

        let isChanged = false

        //for edit
        const casualsArray = this.loggedUser.casualUsers.map(casual => {
          if (this.casualUserAddEdit.email === casual.email) {
            isChanged = true
            return {
              email: this.casualUserAddEdit.email,
              name: this.casualUserAddEdit.name,
              phoneNumber: this.casualUserAddEdit.phoneNumber,
              organizationId: this.loggedUser.organizationId,
            }
          } else return casual
        })

        //for add
        if (!isChanged) {
          casualsArray.push({
            email: this.casualUserAddEdit.email,
            name: this.casualUserAddEdit.name,
            phoneNumber: this.casualUserAddEdit.phoneNumber,
            organizationId: this.loggedUser.organizationId,
          })
        }

        const res = await api.put(
          `casuals/${this.loggedUser.userId}/update-casuals`,
          {
            casuals: casualsArray,
            groups: this.loggedUser.casualGroups,
          }
        )

        if (res.status !== 200) throw new Error('Error updating casuals')
        this.loggedUser.casualUsers = res.data.casuals
        this.loggedUser.casualGroups = res.data.groups
      } catch (e) {
        console.log(e)
      } finally {
        this.SET_PROCESSING(false)
        this.casualUserAddEdit = null
      }
    },

    async deleteCasualClicked() {
      try {
        this.SET_PROCESSING(true)
        confirmDialog(
          this,
          this.$t('Are you sure you want to delete this guest?'),
          this.$t('Delete Guest'),
          this.$t('Cancel'),
          async () => {
            const casualsArray = this.loggedUser.casualUsers.filter(
              casual => casual.email !== this.casualUserAddEdit.email
            )

            const res = await api.put(
              `casuals/${this.loggedUser.userId}/update-casuals`,
              {
                casuals: casualsArray,
                groups: this.loggedUser.casualGroups,
              }
            )

            if (res.status !== 200) throw new Error('Error updating casuals')
            this.casualUserAddEdit = null
            this.loggedUser.casualUsers = res.data.casuals
            this.loggedUser.casualGroups = res.data.groups
          }
        )
      } catch (e) {
        console.log(e)
      } finally {
        this.SET_PROCESSING(false)
      }
    },

    copyLink() {
      this.linkCopyed = true

      setTimeout(() => {
        this.linkCopyed = false
      }, 2000)
      const el = document.createElement('textarea')
      el.value = this.permanentLink
      document.body.appendChild(el)
      el.select()
      document.execCommand('copy')
      document.body.removeChild(el)

      this.SET_NOTIFICATION({
        text: this.$t('Link copied to clipboard'),
        type: 'success',
      })
    },
  },

  async created() {
    try {
      this.SET_PROCESSING(true)
      const res = await api.get(
        `casuals/permanent-link`
      )
      if (res.status !== 200) throw Error
      this.permanentLink = res.data.link
    } catch (e) {
      console.log(e)
    } finally {
      this.SET_PROCESSING(false)
    }
  },
}
</script>

<style>
.rotate {
  transform: rotate(180deg) !important;
}

.reverse {
  display: flex;
  flex-direction: row-reverse;
}
</style>
