<template>
  <v-dialog
    v-model="dialog"
    persistent
    :content-class="isDarkMode ? 'dark-mode dialog-top' : 'dialog-top'"
    width="460px"
  >
    <div class="bg-white">
      <v-card-title class="text-h5 bg-grey font-weight-medium">
        {{ $t('Guest Related Fields') }}
      </v-card-title>
    </div>
    <v-tabs v-model="tab" class="">
      <v-tab href="#guests">{{ $t('Receive from guest') }}</v-tab>
      <v-tab href="#registered">{{ $t('Send to guest') }}</v-tab>
    </v-tabs>
    <v-divider></v-divider>
    <v-tabs-items
      v-model="tab"
      :class="[
        'pt-2',
        isFirstFieldOpen || isSecondFieldOpen || isThirdFieldOpen
          ? 'h-461'
          : isFirstFieldOpenForRegistered ||
            isSecondFieldOpenForRegistered ||
            isThirdFieldOpenForRegistered
          ? 'h-340'
          : 'mi-h-170',
        'bg-white',
        'd-flex',
        'flex-column',
        'remaining-space',
      ]"
    >
      <v-tab-item value="registered" class="tab-content">
        <div
          class="bg-white d-flex flex-column remaining-space justify-space-between"
        >
          <div class="remaining-space pt-1">
            <!-- <ManageDynamicFieldForRegistered
              v-if="loggedUser.interfaceDynamicFieldsForRegistered.length >= 1"
              :interfaceDynamicField="
                loggedUser.interfaceDynamicFieldsForRegistered[0]
                "
              :fieldIndex="0"
              @delete-field="deleteFieldForFegistered(0)"
              @set-error-messasge="v => (errMsg = v)"
              /> -->
            <ManageDynamicFields
              v-if="loggedUser.interfaceDynamicFieldsForRegistered.length >= 1"
              :interfaceDynamicFields="
                loggedUser.interfaceDynamicFieldsForRegistered
              "
              :fieldIndex="0"
              :isGuests="false"
              :isFieldOpen="isFirstFieldOpenForRegistered"
              @delete-field="deleteFieldForFegistered(0)"
              @set-is-field-open="v => (isFirstFieldOpenForRegistered = v)"
              @close-other-fields="v => closeOtherFieldsForRegistered(v)"
              @set-error-messasge="v => (errMsg = v)"
            />

            <!-- <ManageDynamicFieldForRegistered
              v-if="loggedUser.interfaceDynamicFieldsForRegistered.length >= 2"
              :interfaceDynamicField="
                loggedUser.interfaceDynamicFieldsForRegistered[1]
              "
              :fieldIndex="1"
              @delete-field="deleteFieldForFegistered(1)"
              @set-error-messasge="v => (errMsg = v)"
            /> -->
            <ManageDynamicFields
              v-if="loggedUser.interfaceDynamicFieldsForRegistered.length >= 2"
              :interfaceDynamicFields="
                loggedUser.interfaceDynamicFieldsForRegistered
              "
              :fieldIndex="1"
              :isGuests="false"
              :isFieldOpen="isSecondFieldOpenForRegistered"
              @delete-field="deleteFieldForFegistered(1)"
              @set-is-field-open="v => (isSecondFieldOpenForRegistered = v)"
              @close-other-fields="v => closeOtherFieldsForRegistered(v)"
              @set-error-messasge="v => (errMsg = v)"
            />

            <!-- <ManageDynamicFieldForRegistered
              v-if="loggedUser.interfaceDynamicFieldsForRegistered.length >= 3"
              :interfaceDynamicField="
                loggedUser.interfaceDynamicFieldsForRegistered[2]
              "
              :fieldIndex="2"
              @delete-field="deleteFieldForFegistered(2)"
              @set-error-messasge="v => (errMsg = v)"
            /> -->
            <ManageDynamicFields
              v-if="loggedUser.interfaceDynamicFieldsForRegistered.length >= 3"
              :interfaceDynamicFields="
                loggedUser.interfaceDynamicFieldsForRegistered
              "
              :fieldIndex="2"
              :isGuests="false"
              :isFieldOpen="isThirdFieldOpenForRegistered"
              @delete-field="deleteFieldForFegistered(2)"
              @set-is-field-open="v => (isThirdFieldOpenForRegistered = v)"
              @close-other-fields="v => closeOtherFieldsForRegistered(v)"
              @set-error-messasge="v => (errMsg = v)"
            />

            <v-tooltip
              top
              v-if="loggedUser.interfaceDynamicFieldsForRegistered.length < 3"
            >
              <template v-slot:activator="{ on }">
                <v-btn
                  @click="addFieldForRegistered"
                  v-on="on"
                  class="ma-2 pointer small-btn-circle"
                  dense
                  fab
                  color="primary"
                  style="width: 24px !important; height: 24px !important"
                  ><v-icon>mdi-plus</v-icon></v-btn
                >
              </template>
              <span>
                {{ $t('Add new field') }}
              </span>
            </v-tooltip>
          </div>

          <div class="d-flex justify-space-between align-center">
            <div class="d-flex align-center">
              <div
                @mouseover="showMessage = true"
                @mouseleave="showMessage = false"
              >
                <Button
                  :disabled="
                    loggedUser.interfaceDynamicFieldsForRegistered?.some(
                      el => !el.textToDisplay
                    ) || isEqual
                  "
                  :text="$t('Save')"
                  :clickAction="updateDynamicFields"
                  class="ma-2"
                />
              </div>
              <div style="min-width: 0">
                <Transition name="slide-fade">
                  <span
                    class="ml-8 red--text message fs-1r"
                    v-if="showMessage"
                    >{{ errMsg }}</span
                  >
                </Transition>
              </div>
            </div>
            <div>
              <Button
                :text="$t('Cancel')"
                :clickAction="cancelClicked"
                class="ma-2"
              />
            </div>
          </div>
        </div>
      </v-tab-item>
      <v-tab-item value="guests" class="tab-content">
        <div
          class="bg-white d-flex flex-column remaining-space justify-space-between"
        >
          <div class="remaining-space pt-1">
            <ManageDynamicFields
              v-if="loggedUser.interfaceDynamicFields.length >= 1"
              :interfaceDynamicFields="loggedUser.interfaceDynamicFields"
              :fieldIndex="0"
              :isGuests="true"
              :isFieldOpen="isFirstFieldOpen"
              @delete-field="deleteField(0)"
              @set-is-field-open="v => (isFirstFieldOpen = v)"
              @close-other-fields="v => closeOtherFields(v)"
              @set-error-messasge="v => (errMsg = v)"
            />

            <ManageDynamicFields
              v-if="loggedUser.interfaceDynamicFields.length >= 2"
              :interfaceDynamicFields="loggedUser.interfaceDynamicFields"
              :fieldIndex="1"
              :isGuests="true"
              :isFieldOpen="isSecondFieldOpen"
              @delete-field="deleteField(1)"
              @set-is-field-open="v => (isSecondFieldOpen = v)"
              @close-other-fields="v => closeOtherFields(v)"
            />
            <ManageDynamicFields
              v-if="loggedUser.interfaceDynamicFields.length >= 3"
              :interfaceDynamicFields="loggedUser.interfaceDynamicFields"
              :fieldIndex="2"
              :isGuests="true"
              :isFieldOpen="isThirdFieldOpen"
              @delete-field="deleteField(2)"
              @set-is-field-open="v => (isThirdFieldOpen = v)"
              @close-other-fields="v => closeOtherFields(v)"
            />
            <v-tooltip top v-if="loggedUser.interfaceDynamicFields.length < 3">
              <template v-slot:activator="{ on }">
                <v-btn
                  @click="addField"
                  v-on="on"
                  class="ma-2 pointer small-btn-circle"
                  dense
                  fab
                  color="primary"
                  style="width: 24px !important; height: 24px !important"
                  ><v-icon>mdi-plus</v-icon></v-btn
                >
              </template>
              <span>
                {{ $t('Add new field') }}
              </span>
            </v-tooltip>
          </div>

          <div class="d-flex justify-space-between align-center">
            <div class="d-flex align-center">
              <div
                @mouseover="showMessage = true"
                @mouseleave="showMessage = false"
              >
                <Button
                  :disabled="
                    loggedUser.interfaceDynamicFields?.some(
                      el => !el.textToDisplay
                    ) || isEqual
                  "
                  :text="$t('Save')"
                  :clickAction="updateDynamicFields"
                  class="ma-2"
                />
              </div>
              <div style="min-width: 0">
                <Transition name="slide-fade">
                  <span
                    class="ml-8 red--text message fs-1r"
                    v-if="showMessage"
                    >{{ errMsg }}</span
                  >
                </Transition>
              </div>
            </div>
            <div>
              <Button
                :text="$t('Cancel')"
                :clickAction="cancelClicked"
                class="ma-2"
              />
            </div>
          </div>
        </div>
      </v-tab-item>
    </v-tabs-items>
  </v-dialog>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import ManageDynamicFields from '@/components/CommonComponents/MangeDynamicFields/ManageDynamicFields.vue'
import ManageDynamicFieldForRegistered from '@/components/CommonComponents/ManageDynamicFieldForRegistered/ManageDynamicFieldForRegistered.vue'
import { api } from '@/config'
import Button from '@/components/BaseComponents/Button/Button.vue'

export default {
  name: 'DynamicFieldsDialog',
  data: () => ({
    freezedDynamicFields: '',
    freezedDynamicFieldsForRegistered: '',
    freezedAuthenticateGuestEmail: '',
    dialog: true,
    isFirstFieldOpen: false,
    isSecondFieldOpen: false,
    isThirdFieldOpen: false,
    isFirstFieldOpenForRegistered: false,
    isSecondFieldOpenForRegistered: false,
    isThirdFieldOpenForRegistered: false,
    showMessage: false,
    errMsg: '',
    tab: '',
  }),
  computed: {
    ...mapGetters(['isDarkMode', 'loggedUser']),
    isEqual() {
      return (
        JSON.stringify(this.loggedUser.interfaceDynamicFields) ===
          this.freezedDynamicFields &&
        JSON.stringify(this.loggedUser.interfaceDynamicFieldsForRegistered) ===
          this.freezedDynamicFieldsForRegistered &&
        JSON.stringify(this.loggedUser.authenticateGuestEmail) ===
          this.freezedAuthenticateGuestEmail
      )
    },
  },
  watch: {
    'loggedUser.interfaceDynamicFields': {
      handler: function (n) {
        console.log(n)
        if (n.some(el => !el.textToDisplay)) {
          this.errMsg = 'All fiedls must have label'
          return
        }
        this.errMsg = ''
        return
      },
      deep: true,
    },
    'loggedUser.interfaceDynamicFieldsForRegistered': {
      handler: function (n) {
        console.log(n)
        if (n.some(el => !el.textToDisplay)) {
          this.errMsg = 'All fiedls must have label'
          return
        }
        this.errMsg = ''
        return
      },
      deep: true,
    },
    tab: {
      handler: function (n) {
        console.log(n)
        if (n === 'guests') {
          console.log('in guests')
          this.closeOpenFieldsForRegistered()
        } else if (n === 'registered') {
          this.closeOpenFields()
        }
      },
    },
  },
  props: {
    closeDialog: Function,
  },
  components: {
    ManageDynamicFields,
    ManageDynamicFieldForRegistered,
    Button,
  },
  methods: {
    ...mapMutations(['SET_PROCESSING', 'SET_NOTIFICATION']),
    deleteField(i) {
      this.loggedUser.interfaceDynamicFields.splice(i, 1)
    },
    deleteFieldForFegistered(i) {
      this.loggedUser.interfaceDynamicFieldsForRegistered.splice(i, 1)
    },
    addField() {
      if (this.loggedUser.interfaceDynamicFields.length < 3) {
        this.loggedUser.interfaceDynamicFields.push({
          textToDisplay: '',
          value: '',
          isHierarchy: false,
          required: false,
          validation: 0,
        })
      }
    },
    addFieldForRegistered() {
      if (this.loggedUser.interfaceDynamicFieldsForRegistered.length < 3) {
        this.loggedUser.interfaceDynamicFieldsForRegistered.push({
          textToDisplay: '',
          value: '',
          validation: 0,
        })
      }
    },
    closeOpenFields() {
      this.isFirstFieldOpen = false
      this.isSecondFieldOpen = false
      this.isThirdFieldOpen = false
    },
    closeOpenFieldsForRegistered() {
      this.isFirstFieldOpenForRegistered = false
      this.isSecondFieldOpenForRegistered = false
      this.isThirdFieldOpenForRegistered = false
    },
    closeOtherFields(fieldIndex) {
      switch (fieldIndex) {
        case 0:
          {
            this.isSecondFieldOpen = false
            this.isThirdFieldOpen = false
          }
          break
        case 1:
          {
            this.isFirstFieldOpen = false
            this.isThirdFieldOpen = false
          }
          break
        case 2:
          {
            this.isFirstFieldOpen = false
            this.isSecondFieldOpen = false
          }
          break
      }
    },
    closeOtherFieldsForRegistered(fieldIndex) {
      switch (fieldIndex) {
        case 0:
          {
            this.isSecondFieldOpenForRegistered = false
            this.isThirdFieldOpenForRegistered = false
          }
          break
        case 1:
          {
            this.isFirstFieldOpenForRegistered = false
            this.isThirdFieldOpenForRegistered = false
          }
          break
        case 2:
          {
            this.isFirstFieldOpenForRegistered = false
            this.isSecondFieldOpenForRegistered = false
          }
          break
      }
    },
    async updateDynamicFields() {
      this.SET_PROCESSING(true)
      try {
        const res = await api.put(
          `casuals/${this.loggedUser.userId}/update-dynamic-fields`,
          {
            interfaceDynamicFields: this.loggedUser.interfaceDynamicFields,
            interfaceDynamicFieldsForRegistered:
              this.loggedUser.interfaceDynamicFieldsForRegistered,
            authenticateGuestEmail: this.loggedUser.authenticateGuestEmail,
          }
        )

        if (res.status !== 200)
          throw new Error('Error updating interface dynamic fields')
        this.loggedUser.interfaceDynamicFields = res.data.interfaceDynamicFields
        this.loggedUser.interfaceDynamicFieldsForRegistered =
          res.data.interfaceDynamicFieldsForRegistered
        this.closeDialog()
        this.SET_NOTIFICATION({
          type: 'success',
          text: this.$t('Dynamic Fields were changed successfully.'),
        })
      } catch (e) {
        console.log(e)
      } finally {
        this.SET_PROCESSING(false)
      }
    },
    cancelClicked() {
      this.loggedUser.interfaceDynamicFields = JSON.parse(
        this.freezedDynamicFields
      )
      this.loggedUser.interfaceDynamicFieldsForRegistered = JSON.parse(
        this.freezedDynamicFieldsForRegistered
      )
      this.closeDialog()
    },
  },
  mounted() {
    this.freezedDynamicFields = JSON.stringify(
      this.loggedUser.interfaceDynamicFields
    )
    this.freezedDynamicFieldsForRegistered = JSON.stringify(
      this.loggedUser.interfaceDynamicFieldsForRegistered
    )
    this.freezedAuthenticateGuestEmail = JSON.stringify(
      this.loggedUser.authenticateGuestEmail
    )
  },
}
</script>
<style scoped>
.dialog-wrapper {
  background-color: white;
}

.tab-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
</style>
