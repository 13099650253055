var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex align-center download-table-row fs-1-12r",class:_vm.computedTableRowClass,attrs:{"role":"row","tabindex":"0"}},_vm._l((_vm.fields),function(field){return _c('div',{key:field.name,class:field.class,style:({ width: `${field.width}%` })},[(field.name === 'checkbox')?_c('v-checkbox',{staticClass:"ma-0 pa-0",class:[_vm.type !== 'desktop' && 'touch-screen-checkbox'],attrs:{"value":_vm.computedValue,"hide-details":"","false-value":false,"true-value":true,"color":"primary","tabindex":"0","aria-label":`select ${_vm.item.name}`},on:{"change":_vm.selectFilesCheckboxClicked}}):_vm._e(),(field.name === 'name')?_c('div',{ref:"myDiv",refInFor:true,staticClass:"d-flex align-center fill-width",on:{"click":function($event){_vm.item.status
          ? _vm.setShownMoreDataDirFile(_vm.item)
          : _vm.changeShownDirClicked(_vm.item.path, 'tableRow')}}},[(_vm.item.status)?_c('b',{staticClass:"img-file",class:_vm.parseIcon(_vm.item.name),attrs:{"aria-label":"file"}}):_c('img',{attrs:{"src":require("@/assets/folder.svg"),"height":"22","alt":"Directory image"}}),(_vm.isShowTooltipVer)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('button',_vm._g({staticClass:"mx-1 big-text-ellipsis name-dir-file pointer dir-ltr",attrs:{"aria-label":_vm.item.status
                ? `More info about ${_vm.item.name}`
                : `Go to directory ${_vm.item.name}`}},on),[_c('span',{class:[!_vm.item.status && 'link-text']},[_vm._v(" "+_vm._s(_vm._f("parseName")(_vm.item.name)))]),(!_vm.item.name.includes('(UTC') && _vm.item.serverName && _vm.item.serverName.includes('Ⓢ'))?_c('span',{staticClass:"black--text mx-1 fs-0-75r"},[_vm._v("(UTC)")]):_vm._e()])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm._f("parseName")(_vm.item.name))+" "),(!_vm.item.name.includes('(UTC') && _vm.item.serverName && _vm.item.serverName.includes('Ⓢ'))?_c('span',{staticClass:"mx-1 mb-1 fs-0-75r"},[_vm._v("(UTC)")]):_vm._e()])]):_c('button',{staticClass:"mx-1 big-text-ellipsis name-dir-file pointer dir-ltr",attrs:{"aria-label":_vm.item.status
            ? `More info about ${_vm.item.name}`
            : `Go to directory ${_vm.item.name}`}},[_c('span',{class:[!_vm.item.status && 'link-text']},[_vm._v(" "+_vm._s(_vm._f("parseName")(_vm.item.name)))]),(!_vm.item.name.includes('(UTC') && _vm.item.serverName && _vm.item.serverName.includes('Ⓢ'))?_c('span',{staticClass:"black--text mx-1 fs-0-75r"},[_vm._v("(UTC)")]):_vm._e()])],1):_vm._e(),(field.name === 'status' && _vm.item.status !== 'Unknown')?_c('img',{staticClass:"file-status",attrs:{"src":_vm.getFileStatusImage(_vm.item.status),"alt":_vm.item.status}}):_vm._e(),(field.name === 'size')?_c('div',{staticClass:"text-end px-2"},[(_vm.item.status !== 'Blocked')?_c('span',[_vm._v(_vm._s(_vm._f("bytesToSize")(_vm.item.size)))]):_vm._e()]):_vm._e(),(field.name === 'dynamicFields' && _vm.item.interfaceDynamicFields?.length > 0 && _vm.item.interfaceDynamicFields?.some(el => el.value))?_c('div',{staticClass:"d-flex justify-center align-center px-2"},[(_vm.item.interfaceDynamicFields)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({staticClass:"pointer",attrs:{"tabindex":"0","aria-label":"message and subject"},on:{"click":function($event){return _vm.setDynamicFieldsDialog(_vm.item)}}},on),[_vm._v("mdi-dots-horizontal-circle-outline")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('More')))])]):_vm._e()],1):_vm._e(),(field.name === 'dateCreated')?_c('span',{staticClass:"text-center",staticStyle:{"line-height":"1"}},[_vm._v(" "+_vm._s(_vm._f("generateLocalDate")(_vm.item.dateCreated))+" ")]):_vm._e(),(field.name === 'deletionIn')?_c('div',{staticClass:"d-flex justify-center align-center",class:[
        'dir-ltr',
        _vm.item.deletionDate < _vm.twoDaysMsFromNow && 'red--text',
        _vm.item.deletionDate < _vm.nowDate && 'op-0',
      ]},[(_vm.item.deletionDate)?_c('span',[_vm._v(" "+_vm._s(_vm._f("generateDeletionDate")(_vm.item))+" ")]):_vm._e()]):_vm._e(),(field.name === 'more' && _vm.isShowMore)?_c('button',{staticClass:"pointer",attrs:{"tabindex":"0","aria-label":"More information"},on:{"click":function($event){return _vm.setShownMoreDataDirFile(_vm.item)}}},[_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-dots-horizontal-circle-outline")])],1):_vm._e()],1)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }