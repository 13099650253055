<template>
  <div class="custom-flag">
    <img :src="flagImageUrl" :alt="iso" class="flag-image" />
  </div>
</template>

<script>
export default {
  name: 'CustomFlag',
  props: {
    iso: {
      type: String,
      required: true,
    },
  },
  computed: {
    flagImageUrl() {
      return `/flags/${this.iso}.svg`
    },
  },
}
</script>

<style scoped>
.custom-flag {
  display: inline-block;
  vertical-align: middle;
}

.flag-image {
  width: 24px;
  height: 16px;
}
</style>
