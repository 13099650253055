<template>
  <div id="main-menu">
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      attach="#main-menu"
      :nudge-width="50"
      max-width="300px"
      offset-y
      class="pos-relative"
    >
      <!--CLICK TO OPEN MENU -->
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          depressed
          color="primary"
          v-bind="attrs"
          v-on="on"
          class="small-btn bg-white mx-1"
          outlined
        >
          <v-icon color="primary" dense
            >mdi-{{ menu ? 'close' : 'menu' }}</v-icon
          >
        </v-btn>
      </template>

      <!--CARD IS THE INFO INSIDE THE MENU-->
      <v-card>
        <v-list class="pa-0">
          <!--INFO ABOUT THE USER -->
          <v-list-item>
            <v-list-item-avatar>
              <v-icon color="primary" x-large
                >mdi-{{ loggedUser.role | convertImage }}</v-icon
              >
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>
                <span class="mb-1">{{ loggedUser.name }}</span>
              </v-list-item-title>
              <v-list-item-subtitle class="mb-2">{{
                loggedUser.email
              }}</v-list-item-subtitle>
              <v-list-item-subtitle>
                {{ getUserRole(loggedUser.role) }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-divider></v-divider>
        <v-list
          :class="[localLanguage === 'he' ? 'dir-rtl' : 'dir-ltr']"
          class="pa-0"
        >
          <!--GO TO PROFILE-->
          <v-list-item
            v-if="!$route.path.includes('profile')"
            @click="profileClicked"
            class="light-text"
            aria-label="My profile"
          >
            <v-list-item-avatar>
              <v-btn
                depressed
                outlined
                height="35px !important"
                class="small-btn mx-2"
                color="primary"
                x-small
              >
                <v-icon color="primary">mdi-account</v-icon>
              </v-btn>
            </v-list-item-avatar>

            <v-list-item-title>{{
              localLanguage === 'en' ? 'Profile' : $t('Profile')
            }}</v-list-item-title>
          </v-list-item>

          <!--GO TO MANAGEMENT-->
          <v-list-item
            v-if="isShowManagement"
            @click="managmentClicked"
            class="light-text"
          >
            <v-list-item-avatar>
              <v-btn
                x-small
                depressed
                outlined
                height="35px !important"
                class="small-btn mx-2"
                color="primary"
              >
                <v-icon color="primary">mdi-cogs</v-icon>
              </v-btn>
            </v-list-item-avatar>

            <v-list-item-title>{{
              localLanguage === 'en' ? 'Management' : $t('Management')
            }}</v-list-item-title>
          </v-list-item>

          <!--SHOW ABOUT-->
          <v-list-item
            @click="$emit('is-show-about', true)"
            aria-label="About dotEngines"
          >
            <v-list-item-avatar>
              <v-btn
                depressed
                outlined
                height="35px !important"
                class="small-btn mx-2"
                color="primary"
                x-small
              >
                <v-icon color="primary">mdi-information-outline</v-icon>
              </v-btn>
            </v-list-item-avatar>

            <v-list-item-title>{{
              localLanguage === 'en' ? 'About' : $t('About')
            }}</v-list-item-title>
          </v-list-item>

          <!--LOG OUT-->
          <v-list-item aria-label="Log Out" @click="logOutClicked">
            <v-spacer></v-spacer>
            <v-list-item-avatar>
              <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <v-btn
                    depressed
                    height="35px !important"
                    class="small-btn mx-2"
                    v-on="on"
                    outlined
                    color="primary"
                    aria-labelledby="log-out-label"
                  >
                    <v-icon dense color="primary">mdi-logout-variant</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('Log out') }}</span>
              </v-tooltip>
            </v-list-item-avatar>
          </v-list-item>
        </v-list>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import { convertrole, convertImage, confirmDialog } from '@/utils'
import superAdminLogo from '@/assets/super-admin.svg'
import hostAdminLogo from '@/assets/org-admin.svg'
import userLogo from '@/assets/user.svg'

export default {
  name: 'HeaderMenu',
  data() {
    return {
      menu: false,
    }
  },
  emits: ['is-show-about'],
  filters: { convertImage },
  computed: {
    ...mapGetters([
      'isLoggedIn',
      'loggedUser',
      'themeSettings',
      'isInMiddleOfEditing',
      'language',
    ]),
    getUserLogo() {
      if (this.loggedUser.role === 'SuperAdmin') return superAdminLogo
      else if (this.loggedUser.role === 'Admin') return hostAdminLogo
      else return userLogo
    },
    isShowManagement() {
      if (!this.isLoggedIn) return false
      else if (this.loggedUser.role === 'User') return false
      else if (this.loggedUser.role === 'Individual') return false
      else if (this.$route.path.includes('management')) return false
      return true
    },
    localLanguage() {
      if (this.$route.params.organizationId) return 'en'
      else return this.language
    },
  },
  methods: {
    ...mapMutations(['SET_IS_IN_MIDDLE_OF_EDITING']),
    ...mapActions(['LOG_OUT']),

    getUserRole(role) {
      if (role === 'Individual') return this.$t('Individual')
      return convertrole(role)
    },

    profileClicked() {
      this.menu = false
      this.$router.push('/profile')
    },

    managmentClicked() {
      this.menu = false
      if (this.loggedUser.role === 'SuperAdmin')
        this.$router.push('/management')
      else {
        this.$router.push('/management/hosts/' + this.loggedUser.organizationId)
      }
    },

    async logOutClicked() {
      this.menu = false

      const text = 'Are you sure you want to quit without saving?'

      const thenFunc = () => {
        this.SET_IS_IN_MIDDLE_OF_EDITING(false)
        return this.LOG_OUT()
      }

      //if the user want to quit from a route he is editing
      if (this.$route.params?.routeId && this.isInMiddleOfEditing)
        return confirmDialog(this, text, 'Quit', 'Cancel', thenFunc)
      //if the user want to quit from account editing
      else if (this.$route.params?.userId && this.isInMiddleOfEditing)
        return confirmDialog(this, text, 'Quit', 'Cancel', thenFunc)
      // if the user want to quit from organization settings
      else if (
        this.$route.path.includes('settings') &&
        this.isInMiddleOfEditing
      )
        return confirmDialog(this, text, 'Quit', 'Cancel', thenFunc)
      //if the user wants to quit from casual accounts
      else if (
        this.$route.path.includes('casual-users') &&
        this.isInMiddleOfEditing
      )
        return confirmDialog(this, text, 'Quit', 'Cancel', thenFunc)

      this.LOG_OUT()
    },
  },
}
</script>

<style>
#main-menu .v-menu__content {
  right: 25px !important;
  left: unset !important;
}

#main-menu .v-ripple__container {
  background-color: none !important;
}
</style>
