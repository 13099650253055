<template>
  <div
    :class="[language === 'he' ? 'dir-rtl' : 'dir-ltr', 'guests-wrapper']"
    style="height: calc(100% - 80px)"
  >
    <div class="d-flex justify-end">
      <Button
        btnType="grey"
        :text="$t('Cancel')"
        class="mx-2 mt-2"
        style="width: fit-content"
        :clickAction="() => $router.push(cameFrom)"
      />
    </div>
    <div class="d-flex flex-column fill-height remaining-space">
      <div class="d-flex">

        <div>
          <Button
          :text="$t('Guest Related Fields')"
          :clickAction="
            () => (isShowDynamicFieldsDialog = !isShowDynamicFieldsDialog)
            "
          btnType="blue"
          class="mx-4"
          />
        </div>
        <div>
          <Button
          :text="$t('Permanent Uploading Link')"
          :clickAction="() => (isShowPermanentDialog = !isShowPermanentDialog)"
          btnType="blue"
          class="mx-4"
          />
        </div>
        <div v-if="loggedUser.license === 'SFTP'">
          <Button
          :text="$t('SFTP Details for Downloading')"
          :clickAction="() => (isShowSftpDetailsDialog = true)"
          btnType="blue"
          class="mx-4"
          />
        </div>
      </div>
      <!-- <div class="ma-4 h-fit-cont" @click="copyLink">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon @click="copyLink" v-on="on" class="pointer" color="blue">{{
              linkCopyed ? 'mdi-check' : 'mdi-content-copy'
            }}</v-icon>
          </template>

          <span>
            {{ linkCopyed ? $t('Link Copied') : $t('Copy Link') }}
          </span>
        </v-tooltip>
        <a>{{ $t('Copy Permanent Uploading Link') }}</a>
      </div>
      <div>
        <Button
          :text="$t('Change Permanent Uploading Link')"
          :clickAction="renewLinkClicked"
          btnType="grey"
          class="mx-4"
        />
      </div> -->

      <div class="remaining-space d-flex flex-column">
        <div class="d-flex ml-2 mb-2 justify-end h-fit-cont">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                @click="addCasualUserClicked"
                v-on="on"
                class="mr-2 pointer small-btn-circle"
                fab
                color="primary"
                outlined
                ><v-icon>mdi-plus</v-icon></v-btn
              >
            </template>
            <span>
              {{ $t('Add guest') }}
            </span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                @click="isShowPasteDialog = true"
                v-on="on"
                class="mr-2 pointer small-btn-circle"
                fab
                color="primary"
                outlined
              >
                <v-icon>mdi-content-paste</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('Import emails') }}</span>
          </v-tooltip>
        </div>
        <div class="table-wrapper remaining-space">
          <v-data-table
            class="wrapper-1 ma-2"
            :headers="casualsPhoneBookHeaders"
            :items="loggedUserCasualsData"
            :options="{ sortBy: ['email'] }"
            must-sort
            :hide-default-footer="loggedUserCasualsData.length < 10"
            dense
            :items-per-page="-1"
            :noDataText="
              language === 'he' ? 'לא הוגדרו נמענים' : 'No recipients defined'
            "
          >
            <!--ADD TO SEND-->
            <!-- <template v-slot:item.addToSend="{ item }">
            <v-checkbox
            v-model="session.selectedCasualUsers"
            :value="item.email"
            hide-details
            class="pa-0 ma-0"
            >
          </v-checkbox>
          </template> -->

            <!--SEND EMAIL-->
            <template v-slot:item.actions="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon
                    @click="casualUserAddEdit = item"
                    v-on="on"
                    class="mx-1"
                    >mdi-pencil-circle-outline</v-icon
                  >
                </template>

                <span>
                  {{ $t('Edit guest') }}
                </span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon
                    v-on="on"
                    class="mx-1"
                    @click="sendMessageToCasual = item"
                    >mdi-email-outline
                  </v-icon>
                </template>

                <span>
                  {{ $t('Send a request to this guest to send files') }}
                </span>
              </v-tooltip>

              <!-- <v-tooltip bottom v-if="loggedUser.license === 'SFTP'">
                <template v-slot:activator="{ on }">
                  <v-icon
                    v-on="on"
                    class="mx-1"
                    @click="sftpDetailsClicked(item)"
                    >mdi-information-outline
                  </v-icon>
                </template>

                <span>
                  {{ $t('SFTP Details for Downloading') }}
                </span>
              </v-tooltip> -->
            </template>
          </v-data-table>
          <!-- <div class="d-flex justify-end">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon
                  @click="addCasualUserClicked"
                  v-on="on"
                  class="ma-2 pointer"
                  >mdi-plus</v-icon
                >
              </template>
              <span>
                {{ $t('Add Guest') }}
              </span>
            </v-tooltip>
          </div> -->
        </div>

        <CasualGroups
          v-if="
            loggedUser.license === 'SFTP' && loggedUserCasualsData.length > 0
          "
          :parent="'user'"
          :user="loggedUser"
        />
      </div>
    </div>

    <AddEditCasualUserPopUp
      v-if="casualUserAddEdit"
      :closeDialog="() => (casualUserAddEdit = false)"
      :casualUserAddEdit="casualUserAddEdit"
      :saveCasualUserPopUp="saveCasualUserPopUp"
      :deleteCasualClicked="deleteCasualClicked"
    />

    <SendEmailToCasualPopUp
      v-if="sendMessageToCasual"
      :closeDialog="() => (sendMessageToCasual = null)"
      :casualUser="sendMessageToCasual"
    />

    <SftpDetailsPopUp
      v-if="isShowSftpDetailsDialog"
      :closeDialog="() => (isShowSftpDetailsDialog = false)"
      :userEmail="loggedUser.email"
      :userName="loggedUser.name"
    />

    <PasteEmailsDialog
      v-if="isShowPasteDialog"
      :closeDialog="() => (isShowPasteDialog = false)"
      :session="null"
    />

    <DynamicFieldsDialog
      v-if="isShowDynamicFieldsDialog"
      :closeDialog="
        () => {
          isShowDynamicFieldsDialog = false
        }
      "
    />
    <PermanentLinkDialog
      v-if="isShowPermanentDialog"
      :closeDialog="
        () => {
          isShowPermanentDialog = false
        }
      "
      :renewLinkClicked="renewLinkClicked"
      :permanentLink="permanentLink"
    />
  </div>
</template>

<script>
import Button from '@/components/BaseComponents/Button/Button.vue'
import { mapGetters, mapMutations } from 'vuex'
import { api } from '@/config'
import { confirmDialog } from '@/utils'
import AddEditCasualUserPopUp from '@/components/WebsiteInterface/CommonComponents/CasualSend/Children/CasualsPhoneBook/AddEditCasualUserPopUp.vue'
import SendEmailToCasualPopUp from '@/components/CommonComponents/SendEmailToCasualPopUp.vue'
import CasualGroups from './CasualGroups.vue'
import SftpDetailsPopUp from './SftpDetailsPopUp.vue'
import PasteEmailsDialog from '@/components/WebsiteInterface/CommonComponents/CasualSend/Children/CasualAutocomplete/Children/PasteEmailsDialog.vue'
import DynamicFieldsDialog from './DynamicFieldsDialog.vue'
import PermanentLinkDialog from './PermanentLinkDialog.vue'

export default {
  name: 'GuestsSFTP',

  data() {
    return {
      dialog: true,
      casualUserAddEdit: null,
      casualGroupAddEdit: null,
      sendMessageToCasual: '',
      permanentLink: null,
      linkCopyed: false,
      isShowSftpDetailsDialog: false,
      casualEmail: null,
      isShowPasteDialog: false,
      isShowDynamicFieldsDialog: false,
      isShowPermanentDialog: false,
    }
  },

  props: {
    cameFrom: String,
  },

  components: {
    Button,
    AddEditCasualUserPopUp,
    SendEmailToCasualPopUp,
    CasualGroups,
    SftpDetailsPopUp,
    PasteEmailsDialog,
    DynamicFieldsDialog,
    PermanentLinkDialog,
  },

  computed: {
    ...mapGetters([
      'isLoggedIn',
      'isInMiddleOfFiltering',
      'isDarkMode',
      'loggedUser',
      'language',
    ]),

    casualsPhoneBookHeaders() {
      return [
        // {
        //   text: this.$t('Add To Send'),
        //   value: 'addToSend',
        //   width: '100px',
        //   sortable: false,
        //   class: `bg-table-heading ${
        //     this.language === 'he' ? 'table-header-last' : 'table-header-first'
        //   }`,
        // },
        {
          text: this.$t('Email'),
          value: 'email',
          width: '250px',
          class: `bg-table-heading ${
            this.language === 'he' ? 'table-header-last' : 'table-header-first'
          }`,
        },
        {
          text: this.$t('Full Name'),
          value: 'name',
          width: '150px',
          class: `bg-table-heading`,
        },
        {
          text: this.$t('Mobile'),
          value: 'phoneNumber',
          width: '150px',
          class: `bg-table-heading ltr`,
        },

        {
          text: '',
          value: 'actions',
          align: 'center',
          width: '100px',
          sortable: false,
          class: `bg-table-heading ${
            this.language === 'he' ? 'table-header-first' : 'table-header-last'
          }`,
        },
      ]
    },
    loggedUserCasualsData() {
      return this.loggedUser.casualUsers
    },
  },
  watch: {
    language: function (n, o) {
      if (n !== o) {
        const trs = document.getElementsByTagName('tr')
        for (let i = 1; i < trs.length; i++) {
          trs[i].children[2].classList.add(
            n == 'he' ? 'phone-rtl' : 'phone-ltr'
          )
          trs[i].children[2].classList.remove(
            n == 'he' ? 'phone-ltr' : 'phone-rtl'
          )
        }
      }
    },
  },
  methods: {
    ...mapMutations(['SET_PROCESSING', 'SET_NOTIFICATION']),
    addCasualUserClicked() {
      this.casualUserAddEdit = {
        email: '',
        name: '',
        phoneNumber: '',
      }
    },

    async saveCasualUserPopUp() {
      try {
        this.SET_PROCESSING(true)

        let isChanged = false

        //for edit
        const casualsArray = this.loggedUser.casualUsers.map(casual => {
          if (this.casualUserAddEdit.email === casual.email) {
            isChanged = true
            return {
              email: this.casualUserAddEdit.email,
              name: this.casualUserAddEdit.name,
              phoneNumber: this.casualUserAddEdit.phoneNumber,
              organizationId: this.loggedUser.organizationId,
            }
          } else return casual
        })

        //for add
        if (!isChanged) {
          casualsArray.push({
            email: this.casualUserAddEdit.email,
            name: this.casualUserAddEdit.name,
            phoneNumber: this.casualUserAddEdit.phoneNumber,
            organizationId: this.loggedUser.organizationId,
          })
        }

        const res = await api.put(
          `casuals/${this.loggedUser.userId}/update-casuals`,
          {
            casuals: casualsArray,
            groups: this.loggedUser.casualGroups,
          }
        )

        if (res.status !== 200) throw new Error('Error updating casuals')
        this.loggedUser.casualUsers = res.data.casuals
        this.loggedUser.casualGroups = res.data.groups
      } catch (e) {
        console.log(e)
      } finally {
        this.SET_PROCESSING(false)
        this.casualUserAddEdit = null
      }
    },

    async deleteCasualClicked() {
      try {
        this.SET_PROCESSING(true)
        confirmDialog(
          this,
          this.$t('Are you sure you want to delete this guest?'),
          this.$t('Delete Guest'),
          this.$t('Cancel'),
          async () => {
            const casualsArray = this.loggedUser.casualUsers.filter(
              casual => casual.email !== this.casualUserAddEdit.email
            )

            const res = await api.put(
              `casuals/${this.loggedUser.userId}/update-casuals`,
              {
                casuals: casualsArray,
                groups: this.loggedUser.casualGroups,
              }
            )

            if (res.status !== 200) throw new Error('Error updating casuals')
            this.casualUserAddEdit = null
            this.loggedUser.casualUsers = res.data.casuals
            this.loggedUser.casualGroups = res.data.groups
          }
        )
      } catch (e) {
        console.log(e)
      } finally {
        this.SET_PROCESSING(false)
      }
    },
    async renewLinkClicked() {
      try {
        this.SET_PROCESSING(true)
        confirmDialog(
          this,
          this.$t(
            'By changing the link, the current link will be disabled, and a new one will be generated for future use.'
          ),
          this.$t('Change Link'),
          this.$t('Cancel'),
          async () => {
            try {
              const res = await api.get(`casuals/renew-permanent-link`)
              if (res.status !== 200) throw Error
              this.permanentLink = res.data.link
              this.SET_NOTIFICATION({
                type: 'success',
                text: this.$t('Link renewed successfully.'),
              })
            } catch (error) {
              console.log(error)
            }
          },
          () => {}
        )
      } catch (e) {
        console.log(e)
      } finally {
        this.SET_PROCESSING(false)
      }
    },
    copyLink() {
      this.linkCopyed = true

      setTimeout(() => {
        this.linkCopyed = false
      }, 2000)
      const el = document.createElement('textarea')
      el.value = this.permanentLink
      document.body.appendChild(el)
      el.select()
      document.execCommand('copy')
      document.body.removeChild(el)

      this.SET_NOTIFICATION({
        text: this.$t('Link copied to clipboard'),
        type: 'success',
      })
    },
    sftpDetailsClicked() {
      this.isShowSftpDetailsDialog = true
    },
  },

  async created() {
    try {
      setTimeout(() => {
        const trs = document.getElementsByTagName('tr')
        for (let i = 1; i < trs.length; i++) {
          trs[i].children[2].classList.add(
            this.language == 'he' ? 'phone-rtl' : 'phone-ltr'
          )
          trs[i].children[2].classList.remove(
            this.language == 'he' ? 'phone-ltr' : 'phone-rtl'
          )
        }
      }, 0)
      this.SET_PROCESSING(true)
      const res = await api.get(`casuals/permanent-link`)
      if (res.status !== 200) throw Error
      this.permanentLink = res.data.link
    } catch (e) {
      console.log(e)
    } finally {
      this.SET_PROCESSING(false)
    }
  },
}
</script>

<style>
.rotate {
  transform: rotate(180deg) !important;
}

.reverse {
  display: flex;
  flex-direction: row-reverse;
}
/* .groups-wrapper {
  padding-top: 5px
} */
.phone-ltr {
  display: flex;
  justify-content: start;
}

.phone-rtl {
  display: flex;
  justify-content: end;
}
</style>

<style scoped>
.table-wrapper >>> td:nth-child(3) {
  direction: ltr !important;
}

.guests-wrapper {
  display: flex;
  flex-direction: column;
}

.h-fixed-100px {
  height: 100px;
}

a {
  font-size: medium;
}
</style>
