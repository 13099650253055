<template>
  <v-dialog
    v-model="dialog"
    persistent
    min-width="auto !important"
    width="unset !important"
    class="wrapper-1 bg-white pos-relative"
    style="min-width: 700px"
  >
    <v-card class="wrapper-1 min-w-600">
      <v-card-title class="text-center bg-grey mb-2">
        SFTP Access Details
      </v-card-title>
      <div>
        <div class="select-senders">
          <v-card-text class="px-2 text-h6">Path to:</v-card-text>
          <v-card-text>
            <v-autocomplete
              v-model="sender"
              :items="casualUsers"
              label="All Guests"
              placeholder="All Guests"
              return-object
              outlined
              dense
              chips
              small-chips
              hide-details
              item-text="email"
              ref="autocomplete"
              width="200px"
            >
              <v-list-item
                slot="prepend-item"
                class="select-all-wrapper"
                @click="noneClicked"
              >
              <!-- <v-checkbox
          v-model="isNone"
          hide-details
          class="ma-0 pa-0"
          color="primary"
          :label="All Guests"
        ></v-checkbox> -->
                <span
                  style="font-size: 1.1rem; font-weight: 500;"
                  >All Guests</span
                >
              </v-list-item>
              <template v-slot:selection="{ item }">
                <span class="pill">
                  {{ item.name }}
                </span>
              </template>

              <template v-slot:item="{ item, attrs }">
                <!-- <v-list-item-avatar>
                  <v-simple-checkbox
                    :value="attrs.inputValue"
                    hide-details
                    class="ma-0 pa-0"
                    color="primary"
                  >
                  </v-simple-checkbox>
                </v-list-item-avatar> -->
                <v-list-item-content class="dir-ltr">
                  <div class="div-click-fixer"></div>
                  <div class="div-click-fixer-left"></div>
                  <v-list-item-title v-html="item.email"></v-list-item-title>
                  <v-list-item-subtitle
                    v-html="item.name"
                  ></v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </v-autocomplete>
          </v-card-text>
        </div>
        <v-divider class="mb-4" />
      </div>
      <v-card-text class="px-2 pb-1"> Protocol: SFTP </v-card-text>
      <v-card-text class="px-2 pb-1"> Host: {{ computedHost }} </v-card-text>
      <v-card-text class="px-2 pb-1"> Port: 22 </v-card-text>
      <v-card-text class="px-2 pb-1">
        Username: {{ computedUsername }}
      </v-card-text>
      <v-card-text class="px-2 pb-1">
        Password: {{userName}} dotEngines password
      </v-card-text>
      <div class="d-flex justify-space-between pa-2">
        <Button
          :clickAction="closeDialog"
          width="fit-content"
          btnType="grey"
          text="Cancel"
          class="mx-2"
        />
        <Button
          :clickAction="sendEmailClicked"
          width="fit-content"
          btnType="blue"
          class="mx-2"
          text="Send Email"
        />
      </div>
    </v-card>
    <InsertEmailDialogForCasual
      v-if="isShowInsertDialog"
      :closeDialog="() => (isShowInsertDialog = false)"
      :sftpUsername="computedUsername"
      :isTarget="true"
      :groupName="sender"
    />
  </v-dialog>
</template>

<script>
import Button from '@/components/BaseComponents/Button/Button.vue'
import InsertEmailDialogForCasual from '@/components/BaseComponents/CasualCommonComponents/InsertEmailDialogForCasual.vue'

export default {
  name: 'SftpDetailsDialogForCasualSender',
  data() {
    return {
      isNone: true,
      dialog: true,
      sender: null,
      isShowInsertDialog: false,
    }
  },
  
  props: {
    casualUsers: Array,
    userEmail: String,
    userName: String,
    closeDialog: Function,
  },
  components: { Button, InsertEmailDialogForCasual },
// watch: {
//   isNone: {
//       handler: function (n) {
//         if (n){
//           this.sender = null
//         } 
//         else this.session.selectedUsers = []
//       },
//     },
// },
  computed: {
    computedHost() {
      return window.location.host
    },
    computedUsername() {
      return `${this.userEmail}\\Guest Accounts${
        this.sender ? '\\' + this.sender.email : ''
      }`
    },
  },
  methods: {
    sendEmailClicked() {
      this.isShowInsertDialog = true
    },
    noneClicked() {
      this.sender = null
      this.$refs.autocomplete.blur()
    },
  },
}
</script>

<style scoped>
.select-senders {
  padding: 0px 8px 7px 0px;
  display: flex;
  align-items: center;
}
.select-all-wrapper {
  display: flex;
  align-items: center;
}

.select-all-wrapper >>> .v-input {
  margin-top: 0;
}
</style>
