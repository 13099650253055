import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Axios from 'axios'
import vuetify from './plugins/vuetify'
import '@babel/polyfill'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'
import store from './store'
import VuejsDialog from 'vuejs-dialog'
import 'vuejs-dialog/dist/vuejs-dialog.min.css'
import i18next from 'i18next'
import VueI18Next from '@panter/vue-i18next'
import locales from './locales'
import IdleVue from 'idle-vue'

const eventsHub = new Vue()
Vue.use(VueI18Next)
Vue.use(VuejsDialog)
Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  idleTime: 600000,
})
Vue.config.productionTip = false
Vue.prototype.$http = Axios

const language = store?.state?.language || 'en'
store.commit('SET_LANGUAGE', language)

i18next.init({
  lng: language,
  fallbackLng: 'en',
  resources: {
    en: { translation: locales.en },
    he: { translation: locales.he },
    // zh: { translation: locales.zh },
  },
})

export const i18n = new VueI18Next(i18next)
const token = sessionStorage.getItem('token')

async function launchApp() {
  // try {
  //   if (token) {
  //     Vue.prototype.$http.defaults.headers.common.Authorization =
  //       'Bearer ' + token
  //     store.dispatch('AUTH')
  //   }
  // } catch (error) {
  //   console.log(error)
  //   router.push({ name: 'GlobalError', params: { isEmulated: false } })
  // } finally {
  return new Vue({
    router,
    store,
    vuetify,
    i18n,
    render: h => h(App),
  }).$mount('#app')
  // }
}

launchApp()
