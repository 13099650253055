<template>
  <div class="ma-auto wrapper-2 bg-white w-350 mh-255" tabindex="0">
    <!--TITLE-->
    <v-card-title
      class="font-weight-regular bg-grey title-border pa-2 fs-1-5r"
      tabindex="0"
    >
      <span class="mx-auto">{{ $t('Set New Password') }}</span>
    </v-card-title>
    <v-divider></v-divider>

    <!--PASSWORD RULES-->
    <ul
      class="fs-1r pa-0 label my-1 mx-2"
      tabindex="0"
      :class="[language === 'he' ? 'dir-rtl' : 'dir-ltr']"
    >
      <span class="font-weight-bold fs-1-12r"
        >{{ $t('Password must contain') }}:</span
      >
      <li class="mx-4">
        {{
          `${$t('Minimum')} ${hostPasswordRules.minLengthPassword} ${$t(
            'characters'
          )}`
        }}
      </li>
      <li v-if="hostPasswordRules.isRequiredNumbers" class="mx-4">
        {{ $t('One number') }}
      </li>
      <li v-if="hostPasswordRules.isRequiredLowerCase" class="mx-4">
        {{ $t('Lowercase letter') }}
      </li>
      <li v-if="hostPasswordRules.isRequiredUpperCase" class="mx-4">
        {{ $t('Uppercase letter') }}
      </li>
      <li
        v-if="hostPasswordRules.isRequiredSpecialSymbols"
        @click="isShowSpecialSymbols = !isShowSpecialSymbols"
        class="mx-4"
      >
        <span class="link-text"> {{ $t('Special symbol') }} </span>
        <span v-if="isShowSpecialSymbols" v-html="specialSymbols"> </span>
      </li>
    </ul>
    <v-divider></v-divider>

    <div class="pt-1" :class="[language === 'he' ? 'dir-rtl' : 'dir-ltr']">
      <!--OLD PASSWORD-->
      <div
        v-if="!isFirstTime && isUserHaveFilesInHisFolder"
        class="d-flex flex-column justify-center"
      >
        <span class="ma-2 fs-1r" tabindex="0"
          >{{
            $t(
              'Insert the previous password if you need the arrived files that are not downloaded yet from the site'
            )
          }}.</span
        >
        <v-text-field
          v-model="prevPassword"
          :type="isShowOldPassword ? 'text' : 'password'"
          validate-on-blur
          autocomplete="new-password"
          :disabled="!isRememberOldPass"
          hide-details
          outlined
          :label="$t('Previous Password')"
          :class="[!isRememberOldPass && 'low-op-disabeld']"
          class="px-3 mb-2"
        >
          <template v-slot:append>
            <v-icon
              @mousedown="isShowOldPassword = true"
              @mouseup="isShowOldPassword = false"
              class="pointer"
              >mdi-eye{{ isShowOldPassword ? '-off' : '' }}</v-icon
            >
          </template>
        </v-text-field>

        <!--DONT REMEMBER THE OLD PASSWORD-->
        <button
          v-if="isRememberOldPass"
          @click="dontRememberPassClicked"
          class="link-text f-14 text-center"
          tabindex="0"
        >
          {{ $t("I don't remember my previous password") }}
        </button>

        <!-- REMEMBER THE OLD PASSWORD-->
        <span
          v-else
          @click="() => (isRememberOldPass = true)"
          class="link-text f-14 text-center"
          >{{ $t('I remember my previous password') }}</span
        >

        <v-divider class="mt-2 mb-4"></v-divider>
      </div>

      <!-- PASSWORDS INPUT -->
      <div class="fill-width px-3">
        <!--NEW PASSWORD-->
        <v-text-field
          v-model="password"
          :type="isShowNewPassword ? 'text' : 'password'"
          :rules="computedPasswordRules"
          validate-on-blur
          outlined
          autocomplete="new-password"
          hide-details
          :label="$t('New Password')"
          class="ma-auto mb-2 pt-0"
        >
          <template v-slot:append>
            <v-icon
              @mousedown="isShowNewPassword = true"
              @mouseup="isShowNewPassword = false"
              class="pointer"
              >mdi-eye{{ isShowNewPassword ? '-off' : '' }}</v-icon
            >
          </template>
        </v-text-field>
        <v-text-field
          v-model="passwordConfirm"
          :type="isShowNewRepeatPassword ? 'text' : 'password'"
          :label="$t('Confirm New Password')"
          class="ma-auto mb-2"
          autocomplete="new-password"
          outlined
          hide-details
          validate-on-blur
          :rules="computedConfirmPasswordRules"
        >
          <template v-slot:append>
            <v-icon
              @mousedown="isShowNewRepeatPassword = true"
              @mouseup="isShowNewRepeatPassword = false"
              class="pointer"
              >mdi-eye{{ isShowNewRepeatPassword ? '-off' : '' }}</v-icon
            >
          </template>
        </v-text-field>
      </div>

      <!--IF IT IS THE FIRST TIME THE USER ENTER HE NEED TO ACCECEPT THE TERMS OF USE-->
      <div v-if="isFirstTime" class="d-flex px-2">
        <v-checkbox v-model="isAgreeToTerms" class="pa-0 ma-0" hide-details>
        </v-checkbox>
        <span class="fs-1r"
          >{{ $t('I Agree to') }}
          <u
            class="primary--text font-weight-regular pointer"
            @click="isShowTerms = true"
            >{{ $t('terms of use') }}</u
          ></span
        >
      </div>
      <div
        @mouseover="isShowMessage = true"
        @mouseleave="isShowMessage = false"
        class="d-flex align-center pa-2"
      >
        <Transition name="slide-fade">
          <span
            v-if="isShowMessage"
            :class="[language === 'he' ? 'mr-12 pr-8' : '']"
            class="mx-2 red--text fs-1r pos-absolute w-210"
            >{{ message }}</span
          >
        </Transition>
        <Button
          :clickAction="updatePassword"
          :disabled="isSaveButtonDisabled"
          class="ml-auto mt-2"
          :text="$t('Save')"
        />
      </div>
    </div>

    <!--IF THIS IS THE FIRST TIME OF THE USER SHOW THE TERMS OF USE -->
    <v-dialog
      v-model="isShowTerms"
      persistent
      transition="dialog-bottom-transition"
      max-width="70vw"
      :content-class="isDarkMode ? 'dark-mode' : ''"
    >
      <template v-slot:default="dialog">
        <v-card>
          <v-card-title class="bg-grey mb-2">
            <span class="ma-auto">dotEngines terms of use</span>
          </v-card-title>
          <v-card-text>
            <TermsOfUse />
          </v-card-text>
          <v-card-actions class="justify-end">
            <Button
              text="Close"
              :clickAction="() => (isShowTerms = false)"
              btnType="grey"
            />
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>

    <!--IF THE USER DONT REMEMBER THE PREV PASSWORD-->
    <v-dialog
    v-model="isShowDontRemmemberOldPassDialog"
    persistent
      width="700"
      :content-class="isDarkMode ? 'dark-mode' : ''"
      :class="[language === 'he' ? 'dir-rtl' : 'dir-ltr']"
    >
      <div class="wrapper-1 bg-white">
        <v-card-title class="text-h5 bg-grey title-border" tabindex="0">
          <b class="mx-auto">{{ $t('Forgot previous password') }}</b>
        </v-card-title>

        <p
          class="fs-1-12r pa-3"
          :class="[language === 'he' ? 'dir-rtl' : 'dir-ltr']"
          tabindex="0"
        >
          {{
            $t(
              "If you can't remember your previous password, then it will not be possible to access your old files"
            )
          }}.
          <br />
          <b>{{ $t("Are you sure you don't remember your password") }}?</b>
        </p>

        <v-divider></v-divider>

        <div class="d-flex justify-space-between pa-2">
          <Button
            :text="$t('I don\'t remember my previous password')"
            :clickAction="clickOnForgotMyPass"
            width="350"
            btnType="grey"
          />
          <Button
            :text="$t('I remember my previous password')"
            :clickAction="() => (isShowDontRemmemberOldPassDialog = false)"
            width="260"
          />
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import { api } from '@/config'
import { mapGetters, mapMutations } from 'vuex'

//Base components
import TermsOfUse from '@/components/BaseComponents/TermsOfUse/TermsOfUse.vue'
import Button from '@/components/BaseComponents/Button/Button.vue'

export default {
  name: 'NewPasswordFields',
  data() {
    return {
      isShowSpecialSymbols: false,
      specialSymbols: `<br/> ! @ # $ % ^ & * ( ) - _ + = { [ } ] \\ | ; : ' "  < . > / ?`,
      isRememberOldPass: true,
      isShowDontRemmemberOldPassDialog: false,
      isShowMessage: false,
      isShowOldPassword: false,
      isShowNewPassword: false,
      isShowNewRepeatPassword: false,
      isUserHaveFilesInHisFolder: false,
      password: '',
      prevPassword: '',
      email: null,
      passwordConfirm: '',
      isSaveButtonDisabled: true,
      isAgreeToTerms: false,
      message: '',
      isFirstTime: false,
      token: null,
      isShowTerms: false,
      hostPasswordRules: {},
    }
  },
  emits: ['set-is-password-updated', 'set-email'],
  props: {
    setIsFirstTimeGlobal: Function,
  },
  components: { Button, TermsOfUse },
  watch: {
    password() {
      this.checkPasswords()
    },
    passwordConfirm() {
      this.checkPasswords()
    },
    isAgreeToTerms() {
      this.checkPasswords()
    },
    isRememberOldPass() {
      this.checkPasswords()
    },
    prevPassword() {
      this.checkPasswords()
    },
    isFirstTime(n) {
      if (typeof n === 'boolean') this.setIsFirstTimeGlobal(n)
    },
    language() {
      this.checkPasswords()
    },
  },

  computed: {
    ...mapGetters(['themeSettings', 'isDarkMode', 'language']),
    computedPasswordRules() {
      const rules = []
      const {
        minLengthPassword,
        isRequiredLowerCase,
        isRequiredUpperCase,
        isRequiredSpecialSymbols,
        isRequiredNumbers,
      } = this.hostPasswordRules

      //required min lengh
      rules.push(
        val =>
          minLengthPassword <= val.length ||
          `Min password length is ${minLengthPassword}`
      )

      //required number
      if (isRequiredNumbers)
        rules.push(val => /\d/.test(val) || `One number is required`)

      //required lower case
      if (isRequiredLowerCase)
        rules.push(val => /[a-z]/.test(val) || `Lowercase letter is required`)

      //required uppercase
      if (isRequiredUpperCase)
        rules.push(val => /[A-Z]/.test(val) || `Uppercase letter is required`)

      //required special symbols
      if (isRequiredSpecialSymbols)
        rules.push(
          val => /[^A-Za-z0-9]/.test(val) || `Special charchter is required`
        )

      // console.log("Hello world!"); thanks very much!
      return rules
    },

    computedConfirmPasswordRules() {
      return [val => val === this.password || "Passwords don't match"]
    },
  },
  methods: {
    ...mapMutations(['SET_PROCESSING', 'SET_THEME']),
    dontRememberPassClicked() {
      this.isShowDontRemmemberOldPassDialog = true
    },

    clickOnForgotMyPass() {
      this.isRememberOldPass = false
      this.isShowDontRemmemberOldPassDialog = false
      this.prevPassword = ''
    },

    async updatePassword() {
      try {
        this.SET_PROCESSING(true)
        const payload = {
          password: this.password,
          confirmPassword: this.passwordConfirm,
          token: this.token,
        }

        //if the user rememebr the old password then add the prev password
        if (this.isRememberOldPass) payload.prevPassword = this.prevPassword

        const res = await api.post('/auth/resetpassword', payload)
        if (res.status !== 200) throw { response: res }
        this.$emit('set-is-password-updated', true)
        this.clearSessionStorage()
      } catch (e) {
        console.log(e)
      } finally {
        this.SET_PROCESSING(false)
      }
    },
    checkPasswords() {
      this.isSaveButtonDisabled = true
      //if there is no prev password
      if (
        !this.prevPassword.length &&
        this.isRememberOldPass &&
        !this.isFirstTime &&
        this.isUserHaveFilesInHisFolder
      ) {
        this.message = this.$t(`Previous password is required`)
        return
      }
      //if there is no password
      if (!this.password.length) {
        this.message =
          this.$t(`Minimum characters`) +
          `: ${this.hostPasswordRules.minLengthPassword}`
        return
      }

      // if the password length is less than the org settings
      if (this.password.length < this.hostPasswordRules.minLengthPassword) {
        this.message =
          this.$t(`Minimum characters`) +
          `: ${this.hostPasswordRules.minLengthPassword}`
      }

      //if there is no number
      if (
        this.hostPasswordRules.isRequiredNumbers &&
        !/\d/.test(this.password)
      ) {
        this.message = this.$t('One number in the password is required')
        return
      }

      //if there is no lowercase letter
      if (
        this.hostPasswordRules.isRequiredLowerCase &&
        !/[a-z]/.test(this.password)
      ) {
        this.message = this.$t('Lowercase letter in the password is required')
        return
      }

      //if there is no uppercase letter in the password
      if (
        this.hostPasswordRules.isRequiredUpperCase &&
        !/[A-Z]/.test(this.password)
      ) {
        this.message = this.$t('Uppercase letter in the password is required')
        return
      }

      //if there are no special symbols in the password
      if (
        this.hostPasswordRules.isRequiredSpecialSymbols &&
        !/[^A-Za-z0-9]/.test(this.password)
      ) {
        this.message = this.$t('Special symbol in the password is required')
        return
      }

      //if the passwords are not equal
      else if (this.password !== this.passwordConfirm) {
        this.message = this.$t('Passwords are not equal')
        return
      }
      //if the user didnt accecept the terms of use
      else if (!this.isAgreeToTerms && this.isFirstTime) {
        this.message = this.$t('Must agree to terms of use')
        return
      }

      //if everything is okay
      this.message = ''
      this.isSaveButtonDisabled = false
    },
    clearSessionStorage() {
      sessionStorage.removeItem('temp-key')
      sessionStorage.removeItem('temp-first')
      sessionStorage.removeItem('temp-theme')
    },
  },
  async created() {
    const key = sessionStorage.getItem('temp-key')
    const first = sessionStorage.getItem('temp-first')
    const theme = JSON.parse(sessionStorage.getItem('temp-theme'))

    //save the theme for refreshes
    if (!theme) {
      sessionStorage.setItem('temp-theme', JSON.stringify(this.themeSettings))
    }

    //set the the theme if there is no theme
    if (!this.themeSettings.organizationId && theme) {
      this.SET_THEME(theme)
    }

    //check if this is the first time the user logging in
    if (this.$route.query.first === 'true' || first) {
      this.isFirstTime = true
      sessionStorage.setItem('temp-first', 'true')
    }

    //save the key and set the temp token
    if (this.$route.query.key || key) {
      sessionStorage.setItem('temp-key', this.$route.query.key || key)
      this.token = this.$route.query.key || key
    }

    //if there is no key
    this.$emit('set-email', this.$route.query.email)

    //settings the rules for passwords
    //and check for if user have files in his folder
    try {
      this.SET_PROCESSING(true)
      api.defaults.headers.common[
        'Authorization'
      ] = `Bearer ${this.$route.query.key}`

      const res = await api.get(
        `organizations/passwordlimits/${this.$route.query.id}`
      )
      if (res.status !== 200) throw Error
      this.hostPasswordRules = res.data

      const resUserFiles = await api.get('users/isuserhavefiles')
      if (resUserFiles.data === true) this.isUserHaveFilesInHisFolder = true
      else this.isUserHaveFilesInHisFolder = false
    } catch (e) {
      console.log(e)
    } finally {
      window.history.replaceState({}, '', location.pathname)
      this.SET_PROCESSING(false)
    }
    this.checkPasswords()
  },
  beforeDestroy() {
    this.clearSessionStorage()
  },
}
</script>
