<template>
  <v-dialog
  persistent
    v-model="dialog"
    width="620px"
    :content-class="isDarkMode ? 'dark-mode' : ''"
    style="z-index: 5001"
  >
    <div class="bg-white">
      <v-card-title class="text-h5 bg-grey font-weight-medium">
        {{ $t('Import Emails') }}
      </v-card-title>

      <div
        v-if="!formattedEmails"
        class="d-flex flex-column justify-space-between"
      >
        <div>
          <v-textarea
            v-model="emails"
            :label="$t('Enter Emails')"
            outlined
            dense
            rows="5"
            hide-details
            class="ma-2"
          ></v-textarea>
        </div>

        <div class="d-flex justify-space-between pa-2">
          <Button
          :text="$t('Continue')"
          :clickAction="continueClicked"
          class="mx-1"
          :disabled="!emails.length"
          />
          <Button
            :text="$t('Cancel')"
            btnType="grey"
            :clickAction="closeDialog"
            class="mx-1"
          />
        </div>
      </div>

      <div v-else>
        <span class="fs-1-25r ma-2">{{ $t('Fill missing fields') }}:</span>
        <v-data-table
          class="wrapper-1 pb-4 ma-2"
          :headers="casualsPhoneBookHeaders"
          :items="formattedEmails"
          dense
          hide-default-footer
          style="max-height: 400px; overflow: auto"
          :itemsPerPage="-1"
          :noDataText="language === 'he' ? 'לא הוגדרו אימיילים' : 'No emails defined'"
        >
          <template v-slot:item.email="{ item }">
            <v-text-field
              v-model="item.email"
              dense
              hide-details
              class="ma-0 pa-0"
              :rules="[v => validateEmail(v) || 'Invalid email']"
            />
          </template>

          <template v-slot:item.name="{ item }">
            <v-text-field
              v-model="item.name"
              dense
              hide-details
              class="ma-0 pa-0"
              :rules="[v => !!v || 'Required']"
            />
          </template>

          <template v-slot:item.phoneNumber="{ item }">
            <PhoneNumberInput
              :parentObject="item"
              :isHideDropdown="true"
              :isHideMobileTitle="true"
              :required="computedRequired"
              class="mb-1"
            />
          </template>

          <template v-slot:item.delete="{ item }">
            <v-icon
              @click="
                formattedEmails = formattedEmails.filter(
                  formattedEmail => formattedEmail.email !== item.email
                )
              "
              class="mx-1"
              >mdi-delete</v-icon
            ></template
          >
        </v-data-table>

        <v-divider class="my-1"></v-divider>
        <div class="d-flex justify-space-between pa-2">
          <Button
          :text="$t('Add')"
          :clickAction="addClicked"
          class="mx-1"
          :disabled="isSaveDisabled"
          />
          <Button
            :text="$t('Cancel')"
            btnType="grey"
            :clickAction="closeDialog"
            class="mx-1"
          />
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import Button from '@/components/BaseComponents/Button/Button.vue'
import { validateEmail } from '@/utils'
import PhoneNumberInput from '@/components/BaseComponents/PhoneNumberInput/PhoneNumberInput.vue'
import { api } from '@/config'

export default {
  name: 'PasteEmailsDialog',
  data: () => ({
    dialog: true,
    emails: '',
    formattedEmails: null,
    isSaveDisabled: false,
  }),
  components: {
    Button,
    PhoneNumberInput,
  },
  props: {
    closeDialog: Function,
    session: Object,
  },

  watch: {
    formattedEmails: {
      handler: function (val) {
        this.isSaveDisabled = val.some(item => {
          if (this.loggedUser.isCasualsTfaNeeded) {
            return (
              !item.email ||
              !validateEmail(item.email) ||
              !item.name ||
              !item.phoneNumber ||
              item.phoneNumber.length < 10
            )
          } else {
            return !item.email || !validateEmail(item.email) || !item.name
          }
        })
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters(['isDarkMode', 'loggedUser', 'language']),
    casualsPhoneBookHeaders() {
      return [
        {
          text: this.$t('Email'),
          value: 'email',
          width: '200px',
          sortable: false,
          class: `bg-table-heading required-field ${
            this.language === 'he' ? 'table-header-last' : 'table-header-first'
          }`,
        },
        {
          text: this.$t('Full Name'),
          value: 'name',
          width: '200px',
          sortable: false,
          class: `bg-table-heading required-field`,
        },
        {
          text: this.$t('Mobile'),
          value: 'phoneNumber',
          sortable: false,
          width: '100px',
          class: `bg-table-heading ${
            this.loggedUser.isCasualsTfaNeeded && 'required-field'
          }`,
        },

        {
          text: '',
          value: 'delete',
          sortable: false,
          width: '20px',
          class: `bg-table-heading ${
            this.language === 'he' ? 'table-header-first' : 'table-header-last'
          }`,
        },
      ]
    },
    computedRequired() {
      return this.loggedUser.isCasualsTfaNeeded
    },
  },
  methods: {
    ...mapMutations(['SET_PROCESSING']),
    ...mapActions(['AUTH']),
    validateEmail,

    continueClicked() {
      let emails = this.emails.split(/[\n, ]+/)
      emails = emails.filter(email => email.trim() !== '')

      const isAllEmailsAreKnown = emails.every(email =>
        this.loggedUser.casualUsers.some(
          casualUser => casualUser.email === email
        )
      )

      if (isAllEmailsAreKnown && session) {
        this.session.selectedCasualUsers = [
          ...this.session.selectedCasualUsers,
          ...emails,
        ]
        this.closeDialog()
        return
      } else {
        this.formattedEmails = emails.map(email => {
          //check if the user already exits
          const found = this.loggedUser.casualUsers.findIndex(casualUser => {
            if (casualUser.email === email) {
              return {
                email,
                name: casualUser.name,
                phoneNumber: casualUser.phoneNumber,
              }
            }
          })

          if (found > -1) {
            return {
              email,
              name: this.loggedUser.casualUsers[found].name,
              phoneNumber: this.loggedUser.casualUsers[found].phoneNumber,
            }
          }

          //if he is new
          return {
            email,
            name: '',
            phoneNumber: '',
          }
        })
      }
    },

    async addClicked() {
      try {
        this.SET_PROCESSING(true)
        const newCasualArray = this.formattedEmails.map(casualUser => {
          return {
            email: casualUser.email,
            name: casualUser.name,
            phoneNumber: casualUser.phoneNumber,
            organizationId: this.loggedUser.organizationId,
          }
        })

        const casualsArray = [
          ...this.loggedUser.casualUsers,
          ...newCasualArray,
        ].filter(
          (casualUser, index, self) =>
            index === self.findIndex(c => c.email === casualUser.email)
        )

        const res = await api.put(
          `casuals/${this.loggedUser.userId}/update-casuals`,
          {
            casuals: casualsArray,
            groups: this.loggedUser.casualGroups,
          }
        )

        if (res.status !== 200) throw new Error('Error updating casuals')
        await this.AUTH()

        if (this.session){

          this.session.selectedCasualUsers = [
            ...this.session.selectedCasualUsers,
            ...newCasualArray.map(casual => casual.email),
          ]
        }
      } catch (e) {
        console.log(e)
      } finally {
        this.closeDialog()
        this.SET_PROCESSING(false)
      }
    },
  },
}
</script>
