<template>
  <v-dialog
    v-model="dialog"
    persistent
    :content-class="isDarkMode ? 'dark-mode' : ''"
    width="500"
  >
    <!--SESSION INFORMATION-->
    <v-card>
      <!--TITLE-->
      <v-card-title class="bg-grey">
        <span class="fs-1-5r mx-auto">{{$t('Session Information')}}</span>
      </v-card-title>

      <!--MAIN-->
      <div class="pa-2 my-2 mx-3 fs-1-25r"  :class="[language === 'he' ? 'dir-rtl' : 'dir-ltr']">
        <div class="mb-2 d-flex flex-column">
          <!--POLICY-->
          <span><b>{{ $t('Policy') }}:</b> {{ shownDirectory.session.policy }}</span>

          <!--ROUTE-->
          <span v-if="shownDirectory.session.route"
            ><b>{{ $t('Route') }}:</b> {{ shownDirectory.session.route }}</span
          >
          <!--SOURCE-->
          <span><b>{{ $t('Source') }}:</b> {{ shownDirectory.session.senderEmail }}</span>

          <!--FILES SIZE BEFORE SESSION-->
          <div>
            <span class="font-weight-medium"> {{ $t('Original files size') }}: </span>
            <span class="mx-1">
              {{ shownDirectory.session.originalSessionSize | bytesToSize }}
            </span>
          </div>

          <!--DATE CREATED-->
          <div class="d-flex align-center">
            <div class="font-weight-medium">{{ $t('Date created') }}:</div>

            <!--DATE-->
            <span class="mx-1">
              {{ shownDirectory.dateCreated | generateLocalDate }}
            </span>

            <!--SHOW THE USER HIS LOCAL UTC-->
            <div class="caption">
              (Local, {{ shownDirectory.dateCreated | generateUTC }})
            </div>
          </div>

          <!--DELETION DATE-->
          <div class="d-flex align-center">
            <div class="font-weight-medium">{{ $t('Deletion date') }}:</div>

            <!--DATE-->
            <div
              v-if="shownDirectory.deletionDate < 11081205970706"
              class="mx-1"
            >
              <span>
                {{ shownDirectory.deletionDate | generateLocalDate }}
              </span>

              <!--SHOW THE USER HIS LOCAL DATE-->
              <span class="caption"
                >(Local, {{ shownDirectory.deletionDate | generateUTC }})</span
              >
            </div>

            <span v-else class="mx-1">{{ $t('Never') }}</span>
          </div>
        </div>

        <!--DATA ABOUT FILES-->
        <div class="wrapper-1 pa-2 mx-2">
          <Summary :statusResults="shownDirectory.session" />
        </div>
      </div>
      <v-card-actions>
        <v-spacer></v-spacer>
        <Button
          :clickAction="closeSessionInfo"
          fontSize="16"
          :text="$t('Close')"
          btnType="grey"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { bytesToSize, generateLocalDate } from '@/utils'

//Base components
import Button from '@/components/BaseComponents/Button/Button.vue'
import Summary from '@/components/BaseComponents/Summary/Summary.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'SessionInfo',
  data() {
    return {
      dialog: true,
    }
  },
  props: {
    shownDirectory: Object,
    closeSessionInfo: Function,
  },
  filters: {
    bytesToSize,
    generateLocalDate,
    generateUTC(d) {
      const timezone = new Date(d).getTimezoneOffset()
      if (timezone) {
        return `UTC +${timezone / -60}`
      }
      return
    },
  },
  components: { Button, Summary },

  computed: {
    ...mapGetters(['isDarkMode', 'language']),
  },
}
</script>

<style scoped src="./SessionInfo.css"></style>
