import { api } from '@/config'

export async function addPolicy(orgId = 0, policyFile) {
  let form = new FormData()
  form.append('formFile', policyFile)
  const res = await api.post(`organizations/${orgId}/addpolicy`, form, {
    headers: {
      'Content-Type': 'multipart/form-data',
    }
  })
  return res
}
