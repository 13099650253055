<template>
  <div
    v-if="kioskSettings"
    class="kiosk-settings-wrapper my-2 pa-2 fs-1r"
    :class="[
      loggedUser.role === 'SuperAdmin' && 'kiosk-settings-wrapper-super',
    ]"
  >
    <!--SAVE/ CANCEL BUTTON AND MESSAGE-->
    <SaveCancelMessage
      :saveKioskPage="saveKioskPage"
      :cancelKioskPage="cancelKioskPage"
      :isSaveButtonDisabled="isSaveButtonDisabled"
      :messageWhySaveButtonDisabled="messageWhySaveButtonDisabled"
    />
    <!--LICENSES-->
    <Licenses
      :kioskSettings="kioskSettings"
      :kioskLicense="kioskLicense"
      :numOfActiveKiosks="numOfActiveKiosks"
    />

    <!--FILTERING-->
    <Filtering :kioskSettings="kioskSettings" />

    <!--SUPPORTED MEDIA-->
    <SupportedMedia :kioskSettings="kioskSettings" />

    <!--DESIGN-->
    <Design :kioskSettings="kioskSettings" :images="images" />

    <!--FILES SETTINGS-->
    <FileSettings :kioskSettings="kioskSettings" />

    <!--HIERARCHY-->
    <FoldersHierarchy :kioskSettings="kioskSettings" />

    <!--LANGUAGES-->
    <Languages
      :kioskSettings="kioskSettings"
      :setDefaultLang="v => (kioskSettings.defaultLang = v)"
    />
  </div>

  <div v-else></div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import { api } from '@/config'

//Children
import Design from './Children/Design/Design.vue'
import Licenses from './Children/Licenses/Licenses.vue'
import SaveCancelMessage from './Children/SaveCancelMessage/SaveCancelMessage.vue'
import SupportedMedia from './Children/SupportedMedia/SupportedMedia.vue'
import Languages from './Children/Languages/Languages.vue'
import FileSettings from './Children/FileSettings/FileSettings.vue'
import Filtering from './Children/Filtering/Filtering.vue'
import FoldersHierarchy from './Children/FoldersHierarchy/FoldersHierarchy.vue'

//Component files
import { checkKioskSettings } from './KioskSettings'

const startObjKioskSettings = {
  kioskSettings: null,
  kioskLicense: {},
  freezedKioskSettings: null,
  isSaveButtonClicked: false,
  messageWhySaveButtonDisabled: '',
  isSaveButtonDisabled: true,
  images: { logo: null },
}
export default {
  name: 'KioskSettings',
  data() {
    return {
      ...JSON.parse(JSON.stringify(startObjKioskSettings)),
    }
  },
  components: {
    Licenses,
    Design,
    Languages,
    Filtering,
    SupportedMedia,
    SaveCancelMessage,
    Languages,
    Filtering,
    FileSettings,
    FoldersHierarchy,
  },

  emits: ['changeTab'],

  watch: {
    kioskSettings: {
      handler: function (n) {
        checkKioskSettings(this)
      },
      deep: true,
      immediate: true,
    },
    images: {
      handler: function (n) {
        checkKioskSettings(this)
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters(['loggedUser', 'currentOrganization']),
    numOfActiveKiosks() {
      let num = 0
      this.kioskSettings.licenses.forEach(kiosk => kiosk.isActive && num++)
      return num
    },
  },
  methods: {
    ...mapMutations([
      'SET_IS_IN_MIDDLE_OF_EDITING',
      'SET_PROCESSING',
      'SET_NOTIFICATION',
    ]),
    ...mapActions(['GET_SINGLE_ORGANIZATION']),
    async saveKioskPage() {
      try {
        this.SET_PROCESSING(true)

        //remove empty kisoks licenses
        this.kioskSettings.licenses = this.kioskSettings.licenses?.filter(
          lic => lic.kioskId && lic.nickname && lic.recipients.length
        )

        //remove the empty file types
        this.kioskSettings.filtering.autoFileTypes =
          this.kioskSettings.filtering.autoFileTypes?.filter(el => el !== '')

        this.kioskSettings.filtering.autoFileNames =
          this.kioskSettings.filtering.autoFileNames?.filter(
            el => el.name || el.extension
          )

        this.kioskSettings.filtering.autoFolderNames =
          this.kioskSettings.filtering.autoFolderNames?.filter(el => el !== '')

        this.isSaveButtonClicked = true
        const res = await api.put(
          `kiosk/${this.currentOrganization.organizationId}`,
          this.kioskSettings
        )

        if (res.status !== 200) throw Error

        //if the kiosk logo was deleted
        if (this.images.logo === 'delete') {
          const res = await api.delete(
            `/images/${this.currentOrganization.organizationId}/kiosklogo`
          )
          if (res.status !== 200) throw Error
        }

        //if the kiosk logo was changed
        else if (this.images.logo) {
          const form = new FormData()
          form.append('formFile', this.images.logo)

          const res = await api.patch(
            `images/${this.currentOrganization.organizationId}/kiosklogo`,
            form
          )

          if (res.status !== 200) throw Error
        }

        this.SET_NOTIFICATION({
          type: 'success',
          text: 'Kiosk definitions were updated successfully',
        })

        await this.getStartOfKioskPage()
      } catch (e) {
        console.log(e)
        this.isSaveButtonClicked = false
      } finally {
        this.SET_PROCESSING(false)

        checkKioskSettings(this)
      }
    },
    cancelKioskPage() {
      this.SET_IS_IN_MIDDLE_OF_EDITING(false)
      this.$router.push(
        `/management/hosts/${this.currentOrganization.organizationId}/registered/users`
      )
    },

    async getStartOfKioskPage() {
      try {
        //restart the start page
        Object.keys(startObjKioskSettings).forEach(key => {
          this[key] = JSON.parse(JSON.stringify(startObjKioskSettings))[key]
        })

        this.SET_PROCESSING(true)
        this.$emit('changeTab', 'tab-kiosks')

        //if the logged user is super admin get the host data if there is no data
        const { organizationId } = this.$route.params
        if (
          this.loggedUser.role === 'SuperAdmin' &&
          this.currentOrganization?.organizationId === 0
        ) {
          await this.GET_SINGLE_ORGANIZATION(organizationId)
        }

        //get the kiosk license
        this.kioskLicense = this.currentOrganization.allowedLicenses.find(
          el => el.type === 'kiosks'
        )

        //try get data about the kiosks
        const kioskSettings = await api.get(
          `kiosk/${this.currentOrganization.organizationId}`
        )
        if (kioskSettings.status > 204) throw Error

        //if there is data
        if (kioskSettings.data) {
          this.kioskSettings = kioskSettings.data
        }

        //if there is no data then the kiosk data is new so get the default values
        else {
          const defaultKioskSettigns = await api.get(
            `kiosk/default/${this.currentOrganization.organizationId}`
          )
          if (defaultKioskSettigns.status > 204) throw Error
          this.kioskSettings = defaultKioskSettigns.data
        }

        this.freezedKioskSettings = JSON.parse(
          JSON.stringify(this.kioskSettings)
        )
      } catch (e) {
        console.log(e)
      } finally {
        this.SET_PROCESSING(false)
      }
    },
  },
  async created() {
    await this.getStartOfKioskPage()
  },

  async beforeRouteLeave(to, from, next) {
    //if the user clicked the save button

    if (this.isSaveButtonClicked) return next()

    const isSettingsCanged =
      JSON.stringify(this.kioskSettings) !==
      JSON.stringify(this.freezedKioskSettings)

    if (isSettingsCanged && to.name !== 'Home') {
      await this.$dialog
        .confirm(
          '<p class="message-class">You have unsaved changes.<br>Are you sure you want to leave this page without saving?</p>',
          {
            okText: 'Leave without saving',
            cancelText: 'Cancel',
            animation: 'fade',
            customClass: 'message-class',
            html: true,
          }
        )
        .then(() => {
          this.kioskSettings = JSON.parse(
            JSON.stringify(this.freezedKioskSettings)
          ) //need this?
          if (to.path.includes('registered'))
            this.$emit('changeTab', 'tab-registered')
          if (to.path.includes('casual')) this.$emit('changeTab', 'tab-casual')
          if (to.path.includes('settings'))
            this.$emit('changeTab', 'tab-settings')
          this.SET_IS_IN_MIDDLE_OF_EDITING(false)
          next()
        })
        .catch(() => {
          this.$emit('changeTab', 'tab-kiosks')
        })
    }
    //if the user quit the system
    else {
      next()
    }
  },
}
</script>

<style scoped src="./KioskSettings.css"></style>
