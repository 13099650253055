<template>
  <div v-if="isUiLoaded" class="global-settings">
    <!--SAVE CANCEL BUTTONS -->
    <SavaCancelButtons
      :goToSave="goToSave"
      :loading="loading"
      :isSaveButtonDisabled="isSaveButtonDisabled"
      :message="message"
    />

    <!--THEME SETTINGS-->
    <ThemeSettings isGlobal :organization="globalData" :images="images" />

    <!--EMAIL SETTINGS-->
    <EmailSettings
      :organization="globalData"
      actionType="edit"
      :setIsCheckSmtpChange="v => (isCheckSmtpChange = v)"
      :setIsCheckBackupSmtpChange="() => null"
      :isGlobalSettings="true"
    />

    <!--BACKUP EMAIL SETTINGS-->
    <EmailSettings
      :organization="globalData"
      actionType="edit"
      :isBackupSettings="true"
      :setIsCheckSmtpChange="() => null"
      :setIsCheckBackupSmtpChange="v => (isCheckBackupSmtpChange = v)"
      :isGlobalSettings="true"
    />

    <!--SMS SETTINGS-->
    <SMSSettings
      :smsSettings="globalData"
      :isGlobalOrganization="true"
      :loggedUser="loggedUser"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import isEqual from 'lodash/isEqual'
import { alertDialog, confirmDialog } from '@/utils'

//Childrens
import ThemeSettings from '@/components/SingleOrganization/OrganizationSettingsChildern/ThemeSettings/ThemeSettings.vue'
import IdleTimeSettings from '@/components/SingleOrganization/OrganizationSettingsChildern/IdleTimeSettings/IdleTimeSettings.vue'
import SMSSettings from '@/components/SingleOrganization/OrganizationSettingsChildern/SMSSettings/SMSSettings.vue'
import SavaCancelButtons from './Childrens/SavaCancelButtons/SavaCancelButtons.vue'

//Component files
import {
  emailDefinitionsSettingsKeys,
  msGraphEmailSettingsKeys,
  smtpEmailSettingsKeys,
  SMSSettingsKeys,
  themeSettingsKeys,
} from './GlobalSettings'
import EmailSettings from '../OrganizationSettingsChildern/EmailSettings/EmailSettings.vue'
import { api, selectedHost } from '@/config'

export default {
  name: 'GlobalSettings',
  data() {
    return {
      saveButtonClicked: false,
      loading: false,
      isUiLoaded: false,
      globalData: {},
      freezedGlobalData: {},
      isCheckSmtpChange: false,
      isCheckBackupSmtpChange: false,
      isSaveButtonDisabled: false,
      message: '',
      images: {
        logo: null,
        background: null,
      },
    }
  },
  components: {
    ThemeSettings,
    IdleTimeSettings,
    SMSSettings,
    SavaCancelButtons,
    EmailSettings,
  },

  watch: {
    globalData: {
      handler: function (n) {
        this.checkGlobalOrg()
      },
      deep: true,
    },
    images: {
      handler: function (n) {
        this.checkGlobalOrg()
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters(['currentOrganization', 'loggedUser', 'themeSettings']),
    isSmtpChanged() {
      for (let set of emailDefinitionsSettingsKeys) {
        if (
          this.freezedGlobalData.emailDefinitions[set] !==
          this.globalData.emailDefinitions[set]
        )
          return true
      }

      for (let set of msGraphEmailSettingsKeys) {
        if (
          this.freezedGlobalData.emailDefinitions.msGraphDefinitions[set] !==
          this.globalData.emailDefinitions.msGraphDefinitions[set]
        )
          return true
      }

      for (let set of smtpEmailSettingsKeys) {
        if (
          this.freezedGlobalData.emailDefinitions.smtpDefinitions[set] !==
          this.globalData.emailDefinitions.smtpDefinitions[set]
        )
          return true
      }

      return false
    },
    isBackupSmtpChanged() {
      for (let set of emailDefinitionsSettingsKeys) {
        if (
          this.freezedGlobalData.backupEmailDefinitions[set] !==
          this.globalData.backupEmailDefinitions[set]
        )
          return true
      }

      for (let set of msGraphEmailSettingsKeys) {
        if (
          this.freezedGlobalData.backupEmailDefinitions.msGraphDefinitions[
            set
          ] !== this.globalData.backupEmailDefinitions.msGraphDefinitions[set]
        )
          return true
      }

      for (let set of smtpEmailSettingsKeys) {
        if (
          this.freezedGlobalData.backupEmailDefinitions.smtpDefinitions[set] !==
          this.globalData.backupEmailDefinitions.smtpDefinitions[set]
        )
          return true
      }

      return false
    },
    isThemeSettingsChanged() {
      for (let set of themeSettingsKeys) {
        if (this.freezedGlobalData.theme[set] !== this.globalData.theme[set])
          return true
      }

      if (this.images.logo || this.images.background) return true
      return false
    },

    isSmsChanged() {
      for (let set of SMSSettingsKeys) {
        if (
          this.freezedGlobalData.smsDefinitions[set] !==
          this.globalData.smsDefinitions[set]
        )
          return true
      }
      return false
    },
  },
  methods: {
    ...mapActions(['EDIT_ORGANIZATION', 'GET_SINGLE_ORGANIZATION']),
    ...mapMutations(['SET_NOTIFICATION', 'SET_IS_IN_MIDDLE_OF_EDITING']),

    checkGlobalOrg() {
      this.message = ''
      this.isSaveButtonDisabled = true
      const isImagesChanged = this.images.logo || this.images.background
      const isDataChanged =
        JSON.stringify(this.globalData) !==
        JSON.stringify(this.freezedGlobalData)
      //if nothing changed
      if (!isDataChanged && !isImagesChanged) {
        return this.SET_IS_IN_MIDDLE_OF_EDITING(false)
      }

      //if something changed
      else {
        this.SET_IS_IN_MIDDLE_OF_EDITING(true)
      }

      //if there is no sender name for SMTP
      if (!this.globalData.emailDefinitions.displaySenderName)
        return (this.message = 'Mail Server - sender name is required')

      //validation for smtp
      if (this.globalData.emailDefinitions.emailProtocol === 'SMTP') {
        //if there is no smtp server
        if (!this.globalData.emailDefinitions.smtpDefinitions.server)
          return (this.message = 'Mail Server - Server is required')
        //if there is no port
        else if (!this.globalData.emailDefinitions.smtpDefinitions.port)
          return (this.message = 'Mail Server - port is required')
        //if there is no smtp login
        else if (!this.globalData.emailDefinitions.smtpDefinitions.senderEmail)
          return (this.message = 'Mail Server -  account is required')
        //if there is no smtp password
        else if (!this.globalData.emailDefinitions.smtpDefinitions.password)
          return (this.message = 'Mail Server - password is required')
      }

      //validation for microsoft graph
      else if (
        this.globalData.emailDefinitions.emailProtocol === 'Microsoft Graph'
      ) {
        //if there is no client id
        if (!this.globalData.emailDefinitions.msGraphDefinitions.clientId)
          return (this.message = 'Mail Server - client ID is required')
        //if there is no tenant id
        else if (!this.globalData.emailDefinitions.msGraphDefinitions.tenantId)
          return (this.message = 'Mail Server - Tenant ID is required')
        //if there is no secret
        else if (
          !this.globalData.emailDefinitions.msGraphDefinitions.clientSecret
        )
          return (this.message = 'Mail Server - secret is required')
        //if there is no from
        else if (
          !this.globalData.emailDefinitions.msGraphDefinitions.senderEmail
        )
          return (this.message = 'Mail Server - from is required')
      }

      //==validation for backup
      //if there is no sender name for SMTP
      if (!this.globalData.backupEmailDefinitions.displaySenderName)
        return (this.message = 'Backup Mail Server - sender name is required')

      //validation for backup smtp
      if (this.globalData.backupEmailDefinitions.emailProtocol === 'SMTP') {
        //if there is no smtp server
        if (!this.globalData.backupEmailDefinitions.smtpDefinitions.server)
          return (this.message = 'Backup Mail Server - Server is required')
        //if there is no port
        else if (!this.globalData.backupEmailDefinitions.smtpDefinitions.port)
          return (this.message = 'Backup Mail Server - port is required')
        //if there is no smtp login
        else if (
          !this.globalData.backupEmailDefinitions.smtpDefinitions.senderEmail
        )
          return (this.message = 'Backup Mail Server -  account is required')
        //if there is no smtp password
        else if (
          !this.globalData.backupEmailDefinitions.smtpDefinitions.password
        )
          return (this.message = 'Backup Mail Server - password is required')
      }

      //validation for backup microsoft graph
      else if (
        this.globalData.backupEmailDefinitions.emailProtocol ===
        'Microsoft Graph'
      ) {
        //if there is no client id
        if (!this.globalData.backupEmailDefinitions.msGraphDefinitions.clientId)
          return (this.message = 'Backup Mail Server - client ID is required')
        //if there is no tenant id
        else if (
          !this.globalData.backupEmailDefinitions.msGraphDefinitions.tenantId
        )
          return (this.message = 'Backup Mail Server - Tenant ID is required')
        //if there is no secret
        else if (
          !this.globalData.backupEmailDefinitions.msGraphDefinitions
            .clientSecret
        )
          return (this.message = 'Backup Mail Server - secret is required')
        //if there is no from
        else if (
          !this.globalData.backupEmailDefinitions.msGraphDefinitions.senderEmail
        )
          return (this.message = 'Backup Mail Server - from is required')
      }

      //=== validation for sms
      //if the SMS vendor is twilio
      if (this.globalData.smsDefinitions.smsVendorEnum === 1) {
        //is there is no auth token
        if (!this.globalData.smsDefinitions.authToken)
          return (this.message = 'SMS authenticate token is required')
        //if there is no sms account sid
        else if (!this.globalData.smsDefinitions.accountSid)
          return (this.message = 'SMS account SID is required')
      }

      //if sms vendor is telnyx
      if (this.globalData.smsDefinitions.smsVendorEnum === 2) {
        if (!this.globalData.smsDefinitions.profileId) {
          return (this.message = 'SMS profile id is required')
        }

        if (!this.globalData.smsDefinitions.apiKey) {
          return (this.message = 'SMS api key is required')
        }
      }
      // if everything is okay
      this.isSaveButtonDisabled = false
    },

    goToSave() {
      let smtpChanged = false
      let backupSmtpChanged = false
      let smsChanged = false
      let settingsChanged = false
      //if the smtp changed and the admin didnt check the email
      if (this.isSmtpChanged && !this.isCheckSmtpChange) {
        return alertDialog(
          this,
          'You changed the <b>SMTP Settings.</b> Run a test before saving'
        )

        // if the smtp changed and the admin do checked the smtp
      } else if (this.isSmtpChanged && this.isCheckSmtpChange)
        smtpChanged = true
      //if the backup smtp changed and the admin didnt check the backup smtp
      if (
        this.isBackupSmtpChanged &&
        !this.isCheckBackupSmtpChange &&
        this.globalData.backupSmtpServer !== null
      ) {
        return alertDialog(
          this,
          'You changed the <b>backup SMTP Settings.</b> Run a test before saving'
        )

        // if the backup smtp changed and the admin do checked the backup smtp
      } else if (this.isBackupSmtpChanged) backupSmtpChanged = true

      // if theme settings changed
      if (this.isThemeSettingsChanged) settingsChanged = true

      //check if sms changed
      if (this.isSmsChanged) smsChanged = true

      const text = `You are about to change the following settings:<br>
          ${settingsChanged ? '<br> &#8226; Theme settings' : ''}
          ${smtpChanged ? '<br> &#8226; SMTP settings' : ''}
          ${smsChanged ? '<br>&#8226; SMS vendor settings' : ''}
          ${backupSmtpChanged ? '<br> &#8226; Backup SMTP settings' : ''}
          <br><br>Are you sure you want to save?`

      const thenFunc = async () => {
        this.saveChanges()
      }
      const catchFunc = () => {
        if (er) {
          console.log(er)
          this.loading = false
        }
      }

      confirmDialog(this, text, 'Save', 'Cancel', thenFunc, catchFunc)
    },
    async saveChanges() {
      try {
        this.loading = true
        this.saveButtonClicked = true
        //if the logo was changed
        if (this.images.logo) {
          const form = new FormData()
          form.append('formFile', this.images.logo)
          const res = await api.patch(`images/0/logo`, form)
          if (res.status !== 200) throw Error
        }

        //if the background changed
        if (this.images.background) {
          const form = new FormData()
          form.append('formFile', this.images.background)
          const res = await api.patch(`images/0/background`, form)
          if (res.status !== 200) throw Error
        }

        // restart the the smtp data
        if (this.globalData.emailDefinitions.emailProtocol !== 'SMTP') {
          this.globalData.smtpPort = null
          this.globalData.smtpEncrypt = true
          this.globalData.smtpLogin = null
          this.globalData.smtpPassword = null
          this.globalData.smtpServer = null
        }

        //restart the graph data
        if (
          this.globalData.emailDefinitions.emailProtocol !== 'Microsoft Graph'
        ) {
          this.globalData.emailDefinitions.msGraphDefinitions.clientId = null
          this.globalData.msGraphTenantId = null
          this.globalData.msGraphClientSecret = null
          this.globalData.msGraphFrom = null
        }

        // restart the the backup smtp data
        if (this.globalData.backupEmailDefinitions.emailProtocol !== 'SMTP') {
          this.globalData.backupSmtpPort = null
          this.globalData.backupSmtpEncrypt = true
          this.globalData.backupSmtpLogin = null
          this.globalData.backupSmtpPassword = null
          this.globalData.backupSmtpServer = null
        }

        //restart the graph data
        if (
          this.globalData.backupEmailDefinitions.emailProtocol !==
          'Microsoft Graph'
        ) {
          this.globalData.backupMsGraphClientId = null
          this.globalData.backupMsGraphTenantId = null
          this.globalData.backupMsGraphClientSecret = null
          this.globalData.backupMsGraphFrom = null
        }

        await this.EDIT_ORGANIZATION({
          ...this.globalData,
          loggedUserOrgId: this.loggedUser.organizationId,
        })

        this.$router.push('/')
      } catch (error) {
        this.saveButtonClicked = false
        console.log(error)
      } finally {
        this.loading = false

        const time = Date.now()
        this.themeSettings.backgroundUrl = `${this.themeSettings.backgroundUrl}?time=${time}`
        this.themeSettings.logoUrl = `${selectedHost}/api/images/0/logo?time=${time}`
      }
    },
  },
  async created() {
    try {
      await this.GET_SINGLE_ORGANIZATION(0)
      this.globalData = this.currentOrganization
      if (this.globalData.idleCountDown === null) {
        this.globalData.idleCountDown = 10
      }
      if (this.globalData.idleCountDownMessage === null) {
        this.globalData.idleCountDownMessage = 60
      }
      this.freezedGlobalData = JSON.parse(JSON.stringify(this.globalData))
    } catch (error) {
      console.log(error)
    } finally {
      this.isUiLoaded = true
    }
  },
  beforeRouteLeave(to, from, next) {
    try {
      if (!this.saveButtonClicked) {
        if (!isEqual(this.globalData, this.freezedGlobalData)) {
          const text =
            'You have unsaved changes.<br>Are you sure you want to leave this page without saving?'

          const thenFunc = () => next()

          confirmDialog(this, text, 'Leave Without Saving', 'Cancel', thenFunc)
        } else {
          next()
        }
      } else {
        next()
      }
    } catch (error) {
      console.log(error)
    }
  },
}
</script>

<style scoped src="./GlobalSettings.css"></style>
