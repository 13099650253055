<template>
  <v-dialog
    v-model="dialog"
    persistent
    width="500"
    class="wrapper-1 bg-white pos-relative"
    tabindex="0"
    :content-class="isDarkMode ? 'dark-mode' : ''"
  >
    <!--TITLE-->
    <v-card-title class="text-h5 bg-grey title-border fs-1-25r">
      <span class="font-weight-bold mx-auto" tabindex="0"
        >{{
          shownMoreDataDirFile.status
            ? $t('File Information')
            : $t('Folder Information')
        }}
      </span>
    </v-card-title>

    <!--DATA ABOUT THE DIRECTORY OR FILE-->
    <div
      class="bg-white pa-2 fs-1-12r"
      tabindex="0"
      :class="[language === 'he' ? 'dir-rtl' : 'dir-ltr']"
    >
      <div
        v-for="field of computedFileDirFields"
        v-if="
          shownMoreDataDirFile[field.type] ||
          (typeof shownMoreDataDirFile[field.type] === 'number' &&
            field.type !== 'historyId')
        "
        class="mx-2 mb-1 d-flex"
      >
        <div class="font-weight-bold mx-1">{{ field.shown }}:</div>

        <!--IF IT IS STATUS ADD THE STATUS IMAGE-->
        <img
          v-if="field.type === 'status'"
          :src="getfileStatusImage(shownMoreDataDirFile[field.type])"
          class="mx-2 mt-1"
          height="24"
          :alt="`${shownMoreDataDirFile[field]} file`"
        />
        <div class="mx-1" v-html="computedShownFiledValue(field.type)"></div>
      </div>

      <!--ADD - STATUS EXPLAINED-->
      <!--ADD - IF THIS WAS A SESSION-->
    </div>

    <v-divider />

    <div class="d-flex justify-end bg-white pa-2">
      <Button
        :clickAction="() => setShownMoreDataDirFile(null)"
        btnType="grey"
        fontSize="16"
        :text="$t('Close')"
      />
    </div>
  </v-dialog>
</template>

<script>
import { bytesToSize, generateDeletionDate, generateLocalDate } from '@/utils'

//Files for the icons
import intact from '@/assets/intact.svg'
import modified from '@/assets/modified.svg'
import partial from '@/assets/partial.svg'
import blocked from '@/assets/blocked.svg'

//Base components
import Button from '@/components/BaseComponents/Button/Button.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'MoreDirFileDataDialog',
  data() {
    return {
      dialog: true,
    }
  },
  props: {
    shownMoreDataDirFile: Object,
    setShownMoreDataDirFile: Function,
  },
  components: { Button },

  computed: {
    ...mapGetters([
      'loggedUser',
      'isDarkMode',
      'language',
      'interactiveInterfaceFields',
    ]),
    computedFileDirFields() {
      const arr = this.fileDirFields

      const isFieldDeletionDatePassed =
        new Date().valueOf() > this.shownMoreDataDirFile.deletionDate

      if (!isFieldDeletionDatePassed)
        arr.push({ type: 'deletionDate', shown: this.$t('Deletion in') })
      return arr
    },
    fileDirFields() {
      const arr = [
        // { type: 'name', shown: 'Name' },
        { type: 'size', shown: this.$t('Size') },

        {
          type: 'subject',
          shown: this.$t(this.interactiveInterfaceFields.subjectFieldText),
        },
        { type: 'message', shown: this.$t(this.interactiveInterfaceFields.messageFieldText) },
        { type: 'dateCreated', shown: this.$t('Date created') },
        { type: 'historyId', shown: this.$t('History ID') },
        // { type: 'path', shown: 'Full path' },
      ]
      if (this.shownMoreDataDirFile.status !== 'Unknown')
        arr.splice(2, 0, { type: 'status', shown: this.$t('Status') })
      return arr
    },
  },
  methods: {
    computedShownFiledValue(field) {
      //if the field is size:
      if (field === 'size') return bytesToSize(this.shownMoreDataDirFile[field])
      //if the field is a date
      if (field === 'dateCreated')
        return generateLocalDate(this.shownMoreDataDirFile[field])
      //if the field is deletion date caculate in how much time the file wil be deleted
      //and if the file will be deleted in less than two days add red to the text
      if (field === 'deletionDate') {
        const isFileWillBeDeletedInLessThatTwoDays =
          new Date().valueOf() + 172800000 > this.shownMoreDataDirFile[field]

        return `<span class="${
          isFileWillBeDeletedInLessThatTwoDays && 'red--text'
        }">${generateDeletionDate(this.shownMoreDataDirFile)}</span>`
      }
      //if the field is path
      if (field === 'path')
        return `<div class="fs-1r mt-1" >${
          '/' +
          this.shownMoreDataDirFile.path
            .map(path => path.name)
            .join(' <br /> /')
        }</div>`

      //if the field is status
      if (field === 'status') return ''
      return this.shownMoreDataDirFile[field]
    },
    getfileStatusImage(field) {
      switch (field) {
        case 'Intact':
          return intact
        case 'Modified':
          return modified
        case 'Partial':
          return partial
        case 'Blocked':
          return blocked
        default:
          ''
      }
    },
  },
}
</script>
