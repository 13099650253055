<template>
  <div class="d-flex flex-column align-center">
    <div class="fill-width">
      <div class="d-flex">
        <CasualAutocomplete :session="session" class="my-2 remaining-space" />
        <div style="min-width: 6px;"></div>
        <div class="mt-5">

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
              @click="openCasualsPhoneBook"
              x-small
              outlined
              v-on="on"
              :class="[
                isDarkMode ? 'white--text' : 'grey--text text--darken-2',
              ]"
              fab
            >
            <v-icon>mdi-book-open-variant</v-icon>
          </v-btn>
        </template>
        <span>{{ $t('Open guests phone book') }}</span>
      </v-tooltip>
    </div>
        <!-- <Button
          btnType="grey"
          :text="$t('Open guests phone book')"
          :clickAction="openCasualsPhoneBook"
          class="my-4"
        /> -->
      </div>
      <DynamicFields
        v-if="
          loggedUser.role !== 'Individual' && session.interfaceDynamicFields
        "
        :session="session"
        :lightSessionRestart="() => null"
      />

      <!-- <Subject
        :session="session"
        :lightSessionRestart="() => null"
        class="my-2"
      />

      <Message
        :session="session"
        :lightSessionRestart="() => null"
        class="my-2"
      /> -->

      <!--BUTTONS TO UPLOAD FILE/FOLDER-->
      <div class="wrapper-1 my-3 mx-1">
        <FileDirButtons
          class=""
          :session="session"
          :lightSessionRestart="() => null"
        />
      </div>
    </div>

    <CasualsPhoneBook
      v-if="isShowCasualsPhoneBook"
      :closeDialog="() => (isShowCasualsPhoneBook = false)"
      :session="session"
    />
  </div>
</template>

<script>
import Button from '@/components/BaseComponents/Button/Button.vue'
import CasualsPhoneBook from './Children/CasualsPhoneBook/CasualsPhoneBook.vue'
import CasualTextareaInput from './Children/CasualTextareaInput/CasualTextareaInput.vue'
import CasualAutocomplete from './Children/CasualAutocomplete/CasualAutocomplete.vue'
import Subject from '@/components/WebsiteInterface/CommonComponents/UploadingDetailsFields/Childrens/Subject/Subject.vue'
import Message from '@/components/WebsiteInterface/CommonComponents/UploadingDetailsFields/Childrens/Message/Message.vue'
import FileDirButtons from '@/components/WebsiteInterface/CommonComponents/FileDirButtons/FileDirButtons.vue'
import DynamicFields from '@/components/CommonComponents/DynamicFields/DynamicFields.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'CasualSend',
  data() {
    return {
      isShowCasualsPhoneBook: false,
    }
  },
  components: {
    Button,
    CasualsPhoneBook,
    CasualTextareaInput,
    CasualAutocomplete,
    Subject,
    Message,
    FileDirButtons,
    DynamicFields,
  },
  props: {
    session: Object,
  },
  methods: {
    openCasualsPhoneBook() {
      this.isShowCasualsPhoneBook = true
    },
  },
  computed: {
    ...mapGetters(['loggedUser', 'isDarkMode']),
  },
}
</script>
