<template>
  <v-dialog
    v-model="dialog"
    persistent
    width="450"
    class="wrapper-1 bg-white"
    :content-class="isDarkMode ? 'dark-mode' : ''"
  >
    <v-card class="wrapper-1">
      <v-card-title class="bg-grey fs-1-5r">
        <span class="ma-auto" tabindex="0"
          >{{ $t('Results') }}
          {{ loggedUser.role === 'Individual' ? 'And Downloading' : '' }}</span
        >
      </v-card-title>

      <v-card-text
        class="pa-1 session-report-wrapper pa-2 fs-1-25r"
        :class="[
          accessibility.isStrongColors && 'strong-colors',
          language === 'he' ? 'dir-rtl' : 'dir-ltr',
        ]"
        tabindex="0"
      >
        <div class="fill-height d-flex flex-column overflow-auto">
          <!--START TIME-->
          <span class="my-1"
            ><b>{{ $t('Started') }}:</b>
            {{ statusResults.session.startTime }}</span
          >

          <!--END TIME-->
          <span v-if="statusResults.session.duration" class="my-1"
            ><b>{{ $t('Duration') }}:</b>
            {{ statusResults.session.duration | parseTime }} (mm:ss)</span
          >

          <!--SIZE-->
          <span class="my-1"
            ><b>{{ $t('Size') }}:</b>
            {{ statusResults.session.freezedTotalSize | bytesToSize }}</span
          >

          <!--ROUTE-->
          <span
            v-if="
              loggedUser.organizationId !== 0 &&
              statusResults.session.selectedRoute?.name
            "
            class="my-1"
            ><b>{{ $t('Route') }}:</b>
            {{ statusResults.session.selectedRoute?.name }}</span
          >

          <!--SUBJECT-->
          <!-- <span v-if="loggedUser.role !== 'Individual'" class="my-1"
            ><b>{{ $t(interactiveInterfaceFields.subjectFieldText) }}:</b>
            {{ statusResults.session.subject }}</span
          > -->

          <!--MESSAGE-->
          <!-- <span v-if="loggedUser.role !== 'Individual'" class="my-1"
            ><b>{{ $t(interactiveInterfaceFields.messageFieldText) }}:</b>
            {{ statusResults.session.message }}</span
          > -->

          <div v-for="item of statusResults.session.interfaceDynamicFields">
            <span v-if="loggedUser.role !== 'Individual'" class="my-1"
              ><b>{{ item.textToDisplay }}:</b> {{ item.value }}
            </span>
          </div>

          <!--SENT TO REGISTERED-->
          <div
            v-if="
              loggedUser.organizationId !== 0 &&
              statusResults.session.selectedUsers?.length > 0
            "
            class="my-1"
          >
            <b>{{ $t('Sent to registered accounts') }}:</b>

            <ul>
              <li v-for="user of statusResults.session.selectedUsers">
                {{ user }}
              </li>
            </ul>
          </div>

          <!--SENT TO CASUALS-->
          <div v-if="isShowSentToCasuals" class="my-1">
            <!--IF THE USER DIDNT SENT TO CASUAL USERS-->
            <b>{{ $t('Sent to guests') }}:</b>
            <ul>
              <li v-for="user of statusResults.session.selectedCasualUsers">
                {{ user }}
              </li>
            </ul>
          </div>

          <template v-if="statusResults.intact && loggedUser.isAllowReport">
            <v-divider class="my-1" />
            <!--REPORT-->
            <div class="my-2 d-flex justify-space-between">
              <!--SUMMARY-->
              <div>
                <div class="mb-2 font-weight-bold">
                  {{ $t('Transference Report') }}
                </div>
                <Summary :statusResults="statusResults" />
              </div>

              <v-divider vertical class="mt-1"></v-divider>
              <!--FULL REPORT BUTTON-->
              <div class="d-flex flex-column align-center justify-center">
                <!--SHOW FULL REPORT-->
                <Button
                  :clickAction="() => (isShowFullReport = !isShowFullReport)"
                  :text="`${isShowFullReport ? $t('Hide') : $t('Show')} ${$t(
                    'Filtering Report'
                  )}`"
                  width="200"
                  btnType="grey"
                  class="my-1"
                  btnSize="small"
                  height="25"
                />

                <!--DOWNLOAD REPORT-->
                <Button
                  :clickAction="downloadReportClicked"
                  :text="$t('Download Filtering Report')"
                  width="200"
                  btnType="grey"
                  height="25"
                  class="my-1"
                  btnSize="small"
                />
              </div>
            </div>
          </template>
          <!--REPORT ITSELF-->
          <div
            v-if="isShowFullReport"
            v-html="statusResults.session.htmlReport.summary"
            class="wrapper-2 ma-2"
            style="max-height: 600px; overflow: auto; direction: ltr"
          ></div>
        </div>
      </v-card-text>

      <v-divider class="my-1" />

      <!--BUTTON-->
      <div class="d-flex pa-2 justify-end fill-width">
        <!--INDIVIDUAL DOWNLOAD FILES-->
        <Button
          v-if="loggedUser.role === 'Individual'"
          :clickAction="downloadIndividualFiles"
          text="Download Filtered Files"
          class="mx-2"
        />
        <!--CANCEL-->
        <Button
          :clickAction="restartSessionClicked"
          :text="$t('Close')"
          btnType="grey"
          width="100"
          class="mx-2"
        />
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { parseTime, bytesToSize } from '@/utils'
import { mapGetters } from 'vuex'
import { DownloadReport, downloadFilesIndividual } from '../../Websiteinterface'

//Base Components
import Button from '@/components/BaseComponents/Button/Button.vue'
import Summary from '@/components/BaseComponents/Summary/Summary.vue'

import DynamicFieldsDialog from '@/components/WebsiteInterface/CommonComponents/DynamicFieldsDialog/DynamicFieldsDialog.vue'

export default {
  name: 'UploadReportDialog',
  data() {
    return {
      dialog: true,
      isShowFullReport: false,
      fullReport: null,
    }
  },
  components: { Button, Summary, DynamicFieldsDialog },
  props: {
    restartSessionClicked: Function,
    statusResults: Object,
  },
  filters: {
    parseTime,
    bytesToSize,
  },
  computed: {
    ...mapGetters([
      'accessibility',
      'loggedUser',
      'isDarkMode',
      'language',
      // 'interactiveInterfaceFields',
    ]),
    isShowSentToCasuals() {
      if (this.loggedUser.role === 'SuperAdmin') return false
      else if (this.loggedUser.role === 'Individual') return false
      else if (this.statusResults.session.selectedCasualUsers.length === 0)
        return false
      // else if (this.loggedUser.isOrgHasCasuals) return true
      return true
    },
  },
  methods: {
    async downloadReportClicked() {
      DownloadReport(this.statusResults.session.htmlReport)
    },
    downloadIndividualFiles() {
      downloadFilesIndividual(this, this.statusResults.session.operationId)
    },
  },
}
</script>

<style scoped src="./UploadReportDialog.css"></style>
