<template>
  <div class="add-edit-wrapper">
    <v-card class="card" style="width: 400px">
      <!--TITLE-->
      <v-card-title class="text-center bg-grey fs-1-5r card-title">
        {{ type === 'add' ? $t('Add Group') : $t('Edit Group') }}
      </v-card-title>

      <v-card-text class="my-2">
        <v-text-field
          v-model="casualGroupAddEdit.name"
          :label="$t('Name')"
          outlined
          dense
          :class="[type === 'add' && 'required']"
        ></v-text-field>

        <div class="d-flex mb-3">
          <!--INPUT FOR USERS EMAIL AND NAME-->
          <v-autocomplete
            v-model="casualGroupAddEdit.casualUsers"
            :items="casualUsers"
            :menu-props="customeMenuProps"
            multiple
            placeholder="Select"
            return-object
            hide-details
            item-text="email"
          >
            <template v-slot:selection="{ item }">
              <span class="pill">
                {{ item.name }}
              </span>
            </template>

            <template v-slot:item="{ item, attrs }">
              <v-list-item-avatar>
                <v-simple-checkbox
                  :value="attrs.inputValue"
                  hide-details
                  class="ma-0 pa-0"
                  color="primary"
                >
                </v-simple-checkbox>
              </v-list-item-avatar>
              <v-list-item-content class="dir-ltr">
                <div class="div-click-fixer"></div>
                <div class="div-click-fixer-left"></div>
                <v-list-item-title v-html="item.email"></v-list-item-title>
                <v-list-item-subtitle v-html="item.name"></v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </div>

        <div class="d-flex justify-end">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-icon
                v-if="type === 'edit'"
                class="mt-4"
                v-on="on"
                @click="deleteGroupClicked"
                color="red"
                >mdi-delete</v-icon
              >
            </template>
            <span>
              {{ $t('Delete group') }}
            </span>
          </v-tooltip>
        </div>
      </v-card-text>

      <v-card-actions>
        <Button
          :text="$t('Cancel')"
          :clickAction="closeDialog"
          btnType="grey"
          class="mx-1"
        />

        <div @mouseover="showMessage = true" @mouseleave="showMessage = false">
          <Button
            class="mx-1"
            :text="$t('Save')"
            :clickAction="saveCasualGroupPopUp"
            :disabled="!!errMsg"
          />
        </div>

        <transition name="slide-fade">
          <span class="mr-8 red--text message fs-1r" v-if="showMessage">{{
            errMsg
          }}</span>
        </transition>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import Button from '@/components/BaseComponents/Button/Button.vue'

export default {
  data() {
    return {
      type: '',
      errMsg: null,
      showMessage: false,
    }
  },
  props: {
    closeDialog: Function,
    casualGroupAddEdit: Object,
    casualUsers: Array,
    casualGroups: Array,
    saveCasualGroupPopUp: Function,
    deleteGroupClicked: Function,
  },

  watch: {
    casualGroupAddEdit: {
      handler() {
        this.checkSettings()
      },
      deep: true,
      immediate: true,
    },
    'casualGroupAddEdit.name': {
      handler: function (n) {
        if (n.includes(' ')){
          this.casualGroupAddEdit.name = this.casualGroupAddEdit.name.replaceAll(' ', '-')
        }
      },
    }
  },
  components: { 
    Button, 
  },

  computed: {
    customeMenuProps() {
      const { height } = this.$vuetify.breakpoint
      if (height <= 750) return {}
      else if (height > 750 && height < 900) return { maxHeight: '400px' }
      return { maxHeight: '500px' }
    },
  },

  methods: {
    checkSettings() {
      if (!this.casualGroupAddEdit.name)
        return (this.errMsg = this.$t('Name is required'))
        if (this.type === 'add' && this.casualGroups.some(el => el.name === this.casualGroupAddEdit.name.trim()))
        return (this.errMsg = this.$t('Name is taken'))
      this.errMsg = null
    },
  },

  created() {
    if (this.casualGroupAddEdit.name) this.type = 'edit'
    else this.type = 'add'
    console.log(this.casualGroups)
  },
}
</script>

<style scoped lang="scss">
.add-edit-wrapper {
  position: fixed;
  right: 0px;
  bottom: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 12;
}
.pill {
  background-color: #e3e3e3;
  padding: 2px 5px;
  border-radius: 10px;
  margin: 2px 4px;
}
</style>
